import { api } from '.';
import { APIUrl } from './API-Url';

export const myDocumentsApi = api.injectEndpoints({
  endpoints: build => ({
    myDocumentsAuth: build.mutation({
      query: ({ code, mobile_phone }) => ({
        url: `${APIUrl}/lk-user/apply-auth/`,
        method: 'POST',
        body: { mobile_phone, code }
      })
    }),
    myDocumentsSendOtp: build.mutation({
      query: mobile_phone => ({
        url: `${APIUrl}/lk-user/send-otp/`,
        method: 'POST',
        body: mobile_phone
      })
    }),
    createPhysicalCard: build.mutation({
      query: ({ country, delivery_address, pickup_address }) => ({
        url: `${APIUrl}/lk-user/run-physic-card-delivery/`,
        method: 'POST',
        body: { country, delivery_address, pickup_address }
      })
    }),
    getUserInfo: build.query({
      query: () => ({
        url: `${APIUrl}/lk-user/lk-user/`,
        method: 'GET'
      })
    }),
    getDocumentTypePrice: build.query({
      query: () => ({
        url: `${APIUrl}/lk-user/document-type-price/`,
        method: 'GET'
      })
    }),
    createDocument: build.mutation({
      query: ({ document_type, start_date, end_date, iban, card_product }) => ({
        url: `${APIUrl}/lk-user/create-document/`,
        method: 'POST',
        body: { document_type, start_date, end_date, iban, card_product },
        responseHandler: async response => {
          if (response?.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const contentDispositionHeader = response.headers.get('Content-Disposition');
            let filename = null;
            if (contentDispositionHeader) {
              const matches = contentDispositionHeader.match(/filename\*=(.*?)$/);
              if (matches && matches.length > 1) {
                const encodedFilename = matches[1];
                filename = decodeURIComponent(encodedFilename);
              } else {
                filename = contentDispositionHeader.split('filename=')[1];
              }
            }
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename.split("utf-8''")[1]}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
        }
      })
    }),
    downloadDocument: build.query({
      query: uuid => ({
        url: `${APIUrl}/lk-user/download-doc/${uuid}/`,
        method: 'GET'
      })
    }),
    getUserDocuments: build.query({
      query: () => ({
        url: `${APIUrl}/lk-user/documents/`,
        method: 'GET'
      })
    }),
    getUserAccounts: build.query({
      query: () => ({
        url: `${APIUrl}/lk-user/accounts/`,
        method: 'GET'
      })
    })
  })
});

export const {
  useMyDocumentsAuthMutation,
  useMyDocumentsSendOtpMutation,
  useCreatePhysicalCardMutation,
  useGetUserInfoQuery,
  useGetDocumentTypePriceQuery,
  useCreateDocumentMutation,
  useDownloadDocumentQuery,
  useGetUserDocumentsQuery,
  useGetUserAccountsQuery
} = myDocumentsApi;
