import React from 'react';

const DocIcon = () => {
  return (
    <svg width='14' height='18' viewBox='0 0 14 18' fill='#72BF44' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.32575 0.666016C1.69992 0.666016 0.357422 1.89935 0.357422 3.45269V14.5461C0.357422 16.0994 1.69992 17.3327 3.32575 17.3327H10.7224C12.3483 17.3327 13.6908 16.0994 13.6908 14.5461C13.6908 14.0036 13.6908 8.0477 13.6908 6.49937C13.6908 6.49103 13.6908 6.48186 13.6908 6.47353C13.6908 3.50435 10.8249 0.666016 7.85742 0.666016C4.34576 0.666016 4.33742 0.666016 3.32575 0.666016ZM8.69076 2.43685C10.1674 2.82852 11.5374 4.19353 11.9199 5.66603H9.99242C9.25159 5.66603 8.69076 5.14103 8.69076 4.54603V2.43685ZM4.52409 5.66603H5.35742C5.81742 5.66603 6.19076 6.03937 6.19076 6.49937C6.19076 6.95937 5.81742 7.3327 5.35742 7.3327H4.52409C4.06409 7.3327 3.69076 6.95937 3.69076 6.49937C3.69076 6.03937 4.06409 5.66603 4.52409 5.66603ZM4.52409 8.99937H9.52409C9.98409 8.99937 10.3574 9.37271 10.3574 9.83271C10.3574 10.2927 9.98409 10.666 9.52409 10.666H4.52409C4.06409 10.666 3.69076 10.2927 3.69076 9.83271C3.69076 9.37271 4.06409 8.99937 4.52409 8.99937ZM4.52409 12.3327H9.52409C9.98409 12.3327 10.3574 12.7061 10.3574 13.1661C10.3574 13.6261 9.98409 13.9994 9.52409 13.9994H4.52409C4.06409 13.9994 3.69076 13.6261 3.69076 13.1661C3.69076 12.7061 4.06409 12.3327 4.52409 12.3327Z'
        fill='#72BF44'
      />
    </svg>
  );
};
export default DocIcon;
