export const apply = {
  title: 'Введите номер телефона',
  subtitle: 'к которому будет привязана карта',
  mobile_phone: 'Телефон',
  checkbox: {
    text: 'Я соглашаюсь',
    link: 'с условиями выпуска и обслуживания предоплаченных карт'
  }
};

export const verify = {
  title: 'Введите код',
  subtitle: 'Код отправлен на номер {{phone}}',
  change_number: 'Изменить номер',
  send_after: 'Отправить код повторно через {{seconds}} сек',
  send_sms: 'Отправить код'
};

export const choice_product = {
  title: 'Выберите номинал карты',
  usd: 'долларов',
  comission: 'Комиссия за выпуск — {{amount}} тенге',
  buy: 'Купить за {{amount}} $',
  exchange: 'Обменный курс — {{amount}} тенге за доллар',
  modal: {
    choosed: 'Вы выбрали карту номиналом {{amount}}$.',
    comission: 'Стоимость с учётом комиссии {{amount}}$',
    confirm: 'Подтверждаю'
  }
};

export const success = {
  title: 'Цифровая предоплаченная карта № {{number}} \n успешно выпущена',
  subtitle: 'Сохраните этот номер и ожидайте СМС с остальными данными карты',
  subtitle2: ' (придёт в течение 30 минут).',
  button: 'Перейти в «Мои документы»',
  subtitle3: 'Все ваши карты, баланс и выписка по операциям',
  warning: 'Карта привязана к вашему номеру телефона, поэтому храните его и данные карты в секрете',
  bunner: {
    title: 'Добавьте карту в электронный кошелёк',
    subtitle: 'и оплачивайте покупки смартфоном'
  }
};

export const waiting = {
  create_card: 'Идет процесс создания карточки',
  no_more_5: 'Процесс займет не более 5 минут',
  payment: 'Идет обработка платежа',
  wait: 'Пожалуйста, подождите...'
};

export const error_descriptions = {
  rejected: 'Ваш платеж отклонен. Для оформления Prepaid карты подайте заявку заново',
  canceled: 'Ваша заявка была отменена. Для оформления Prepaid карты подайте заявку заново',
  technical: 'По техническим причинам сервис временно недоступен',
  session: {
    title: 'Время активной сессии истекло',
    subtitle: 'Для оформления Prepaid карты начните сначала',
    button: 'Начать сначала'
  }
};

export const buttons = {
  card_page: 'На страницу карты',
  continue: 'Далее',
  my_cards: 'Перейти в «Мои карты»',
  try: 'Повторить попытку',
  main_page: 'Вернуться на главный экран'
};
