import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { PERSIST, persistReducer, persistStore, PURGE, REGISTER } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';

import { api } from '@/app/api';
import { notificationMiddleware } from '@/app/middlewares/notification';
import appSlice from '@/app/store/slices/app';
import authSlice from '@/app/store/slices/auth';
import cardsSlice from '@/app/store/slices/cards';
import channelSlice from '@/app/store/slices/channel';
import errorSlice from '@/app/store/slices/error';
import requestTimeSlice from '@/app/store/slices/requestTimer';

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  version: 1,
  blacklist: ['api']
};

const appLocalStoragePersistConfig = {
  key: 'channel',
  storage: localStorage,
  version: 1
};

export const rootReducer = () => {
  return combineReducers({
    app: appSlice,
    auth: authSlice,
    channel: persistReducer(appLocalStoragePersistConfig, channelSlice),
    errors: errorSlice,
    cards: cardsSlice,
    requestTimestamp: requestTimeSlice,
    [api.reducerPath]: api.reducer
  });
};

const persistedReducer = persistReducer(persistConfig, rootReducer());

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          REGISTER,
          PERSIST,
          PURGE,
          'channel/setExpiredAtSumsub',
          'requestTimeSlice/setRequestTimestamp'
        ],
        ignoredPaths: ['channel.expired_at_sumsub', 'requestTimestamp.lastRequestTime']
      }
    }).concat(api.middleware, notificationMiddleware),
  devTools: import.meta.env.DEV
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
