import * as React from 'react';

function TransferIcon(props) {
  return (
    <svg width={25} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2.5 8.5H15M6.5 16.5h2M11 16.5h4'
        stroke='#70FF6E'
        fill='none'
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M22.5 14.03v2.08c0 3.51-.89 4.39-4.44 4.39H6.94c-3.55 0-4.44-.88-4.44-4.39V7.89c0-3.51.89-4.39 4.44-4.39H15M20.5 9.5v-6l2 2M20.5 3.5l-2 2'
        fill='none'
        stroke='#70FF6E'
        strokeWidth={1.5}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default TransferIcon;
