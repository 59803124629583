import * as React from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { ThemeContext } from '@/contexts/theme-context';

function SamsungPayIcon(props) {
  const location = useLocation().pathname;
  const { theme } = useContext(ThemeContext);
  const isDark = theme === 'dark' && location.includes('success');
  return (
    <svg width={79} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M70.834 6.773v7.095H68.24L66.461 8.03h-.048l.096 5.838h-1.778V6.773h2.691l1.682 5.624h.024l-.096-5.624h1.802zm-37.34 7.166l1.346-7.166h3.22l1.345 7.166H37.46l-.985-6.526h-.024l-1.01 6.526h-1.946zm15.282-7.166l.168 7.166h-1.826l-.048-6.454h-.048l-1.201 6.454h-1.85l-1.202-6.454h-.048l-.048 6.454h-1.826l.168-7.166h2.98l.889 5.482h.024l.913-5.482h2.955zm11.366 6.075c.505 0 .672-.333.697-.523.023-.07.023-.19.023-.284V6.773h1.85v5.15c0 .142-.023.402-.023.474-.12 1.329-1.202 1.78-2.548 1.78-1.345 0-2.402-.428-2.523-1.78 0-.072-.024-.332-.024-.475V6.773h1.85v5.315c0 .095 0 .19.025.285.024.143.168.475.673.475zm-29.723-.95c.072.166.048.404.024.522-.072.238-.217.475-.721.475-.457 0-.721-.261-.721-.64v-.689h-1.946v.546c0 1.614 1.273 2.088 2.643 2.088 1.321 0 2.403-.45 2.57-1.637.097-.617.025-1.02 0-1.187-.312-1.494-3.075-1.945-3.267-2.8a1.001 1.001 0 010-.379c.048-.237.216-.475.673-.475.432 0 .673.261.673.641v.451h1.802v-.498c0-1.567-1.418-1.804-2.451-1.804-1.298 0-2.355.427-2.547 1.59a2.68 2.68 0 00.024.973c.288 1.494 2.883 1.898 3.244 2.824zm23.62 0c.072.166.047.38.023.522-.048.238-.216.475-.696.475-.457 0-.721-.261-.721-.64v-.689h-1.922v.546c0 1.59 1.273 2.065 2.619 2.065 1.297 0 2.379-.428 2.547-1.614.096-.617.024-1.02 0-1.163-.313-1.495-3.028-1.922-3.22-2.752a.674.674 0 010-.38c.048-.237.216-.475.649-.475.432 0 .673.261.673.641v.45h1.802v-.497c0-1.543-1.418-1.78-2.427-1.78-1.274 0-2.33.403-2.523 1.566-.048.309-.072.593.024.95.264 1.446 2.811 1.85 3.172 2.776zm21.264.878c.529 0 .697-.332.745-.521.024-.072.024-.19.024-.262v-1.044h-.745V9.905H77.9v1.922c0 .143 0 .237-.025.475-.12 1.305-1.273 1.78-2.57 1.78-1.274 0-2.428-.475-2.548-1.78-.024-.238-.024-.332-.024-.475V8.813c0-.118.024-.355.024-.474.168-1.353 1.25-1.78 2.547-1.78 1.298 0 2.427.427 2.547 1.78.025.213.025.474.025.474v.238h-1.827v-.404s0-.166-.024-.284c-.024-.166-.168-.522-.72-.522-.53 0-.674.356-.721.522-.025.095-.025.213-.025.332v3.274c0 .095 0 .19.025.261 0 .238.192.546.72.546z'
        fill={isDark ? '#fff' : '#000'}
      />
      <path
        d='M20.092 7.01c-.24-1.85-.913-3.536-2.09-4.77-1.25-1.162-2.956-1.827-4.83-2.064C11.37-.06 10.145.01 10.145.01S8.919-.037 7.117.176c-1.874.237-3.58.902-4.83 2.065C1.11 3.474.437 5.159.197 7.01-.043 8.79.005 10 .005 10s-.049 1.21.168 2.99c.24 1.85.913 3.536 2.09 4.77 1.25 1.162 2.956 1.826 4.83 2.064 1.802.237 3.027.166 3.027.166s1.226.047 3.028-.166c1.874-.238 3.58-.902 4.83-2.065 1.177-1.233 1.85-2.918 2.09-4.77.24-1.779.168-2.989.168-2.989s.096-1.21-.144-2.99z'
        fill='#1E4BC6'
      />
      <path
        d='M3.729 7.082h1.826c.288 0 .528.047.769.142.24.095.432.237.6.403.168.166.313.356.409.594.096.237.144.474.144.735s-.048.498-.144.736a1.75 1.75 0 01-.409.593 2.043 2.043 0 01-.6.403 1.93 1.93 0 01-.77.143h-.816v1.495h-.985V7.082h-.024zm.985.901v1.923h.769c.144 0 .288-.024.408-.072a.915.915 0 00.505-.522.973.973 0 00.072-.38.973.973 0 00-.072-.379 1.343 1.343 0 00-.193-.308.915.915 0 00-.312-.214 1.135 1.135 0 00-.408-.071h-.77v.023zm5.046 4.343a2.194 2.194 0 01-1.442-.546 2.262 2.262 0 01-.48-.664 2.163 2.163 0 01-.193-.902c0-.332.048-.64.168-.902.12-.26.289-.474.48-.664.193-.19.433-.309.674-.404.264-.095.504-.142.793-.142.288 0 .552.047.768.142.24.095.433.238.601.404v-.546h.985v4.223h-.985v-.593c-.144.19-.36.333-.6.451-.24.095-.481.143-.77.143zm.168-.878c.192 0 .36-.024.504-.095.145-.071.289-.142.409-.261.12-.119.192-.237.264-.38.072-.143.096-.308.096-.474a1.05 1.05 0 00-.096-.475c-.072-.142-.144-.285-.264-.38a1.2 1.2 0 00-.409-.26.986.986 0 00-.504-.12c-.168 0-.337.025-.505.096a1.201 1.201 0 00-.408.26.993.993 0 00-.265.38 1.05 1.05 0 00-.096.475c0 .166.024.332.096.474.073.143.169.285.265.404.12.119.264.19.408.261.144.047.313.095.505.095zm3.556-3.322l1.201 2.87 1.106-2.87h1.033L14.3 14.248h-1.01l.89-2.112-1.73-4.01h1.033z'
        fill='#fff'
      />
    </svg>
  );
}

export default SamsungPayIcon;
