import React from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import BucketIcon from '@/assets/images/icons/BucketIcon';
import TransferIcon from '@/assets/images/icons/TransferIcon';
import { BodyText, SubBody } from '@/components';

const translateToKazakhMonth = monthName => {
  console.log('monthName', monthName);
  const kazakhMonths = {
    anuary: 'қаңтар',
    february: 'ақпан',
    march: 'наурыз',
    april: 'сәуір',
    may: 'мамыр',
    june: 'маусым',
    july: 'шілде',
    august: 'тамыз',
    september: 'қыркүйек',
    october: 'қазан',
    november: 'қараша',
    december: 'желтоқсан'
  };

  return kazakhMonths[monthName] || monthName;
};

function TransactionList({ transactions, dates, filterTransactions, filter }) {
  const { i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];

  const convertDate = useCallback(
    date => {
      if (lang === 'kk') {
        const today = new Date();
        const targetDate = new Date(date);
        if (targetDate.toLocaleDateString() === today.toLocaleDateString()) {
          return 'Бүгін';
        } else {
          const day = targetDate.getDate();
          const monthName = translateToKazakhMonth(
            targetDate.toLocaleString('en-US', { month: 'long' }).toLocaleLowerCase()
          );
          return `${day} ${monthName}`;
        }
      } else {
        if (new Date(date).toLocaleDateString() === new Date().toLocaleDateString()) {
          return 'Сегодня';
        } else {
          const formattedDate = new Date(date).toLocaleString('ru-RU', {
            day: 'numeric',
            month: 'long',
            timeZone: 'UTC'
          });
          return formattedDate;
        }
      }
    },
    [i18n]
  );

  return (
    <>
      {dates?.map(date => {
        return (
          <div key={date} tw='text-left'>
            <SubBody text={convertDate(date)} twStyle={tw`text-secondary`} />
            <div tw='flex flex-col divide-grey divide-y'>
              {filterTransactions(new Date(date), transactions)?.map(item => (
                <div tw='flex py-5 w-full justify-between gap-5' key={item?.transaction_id}>
                  <div tw='flex gap-2'>
                    {item?.incfl_name === 'списание' ? <BucketIcon /> : <TransferIcon />}
                    <div tw='flex flex-col gap-3 text-left'>
                      <BodyText text={item?.operation_name} />
                      <SubBody
                        text={new Date(item?.time).toLocaleTimeString([], {
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: false
                        })}
                        twStyle={tw`text-secondary`}
                      />
                    </div>
                  </div>
                  <div tw='flex flex-col items-end gap-3'>
                    <BodyText text={`– ${item?.amount}$`} twStyle={tw`whitespace-nowrap`} />
                    {item?.commission && <SubBody text={`комисся ${item?.commission}`} twStyle={tw`text-secondary`} />}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default TransactionList;
