import * as React from 'react';

import { DeviceInfoContext } from '@/contexts/device-info-context';

function CartIcon(props) {
  const { isMobile } = React.useContext(DeviceInfoContext);

  return isMobile ? (
    <svg width={36} height={30} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={10.716} cy={25.453} fill='url(#prefix__paint0_linear_211_1040)' r={4.214} />
      <circle cx={22.384} cy={25.453} fill='url(#prefix__paint1_linear_211_1040)' r={4.214} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M26.889 1.572a2.107 2.107 0 011.92-1.239h4.514a2.107 2.107 0 010 4.214h-3.155l-2.3 5.082a2.107 2.107 0 01-3.838-1.737l2.859-6.32z'
        fill='url(#prefix__paint2_linear_211_1040)'
      />
      <g filter='url(#prefix__filter0_bi_211_1040)'>
        <path
          d='M1.988 11.348C1.32 8.812 3.233 6.33 5.856 6.33h21.71c2.623 0 4.537 2.482 3.87 5.018l-2.758 10.475a4 4 0 01-3.868 2.982H8.612a4 4 0 01-3.868-2.982L1.988 11.348z'
          fill='#72BF44'
          fillOpacity={0.5}
        />
      </g>
      <defs>
        <linearGradient
          id='prefix__paint0_linear_211_1040'
          x1={6.502}
          y1={21.239}
          x2={13.85}
          y2={20.385}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FB84E' />
          <stop offset={1} stopColor='#2A8640' />
        </linearGradient>
        <linearGradient
          id='prefix__paint1_linear_211_1040'
          x1={18.17}
          y1={21.239}
          x2={25.518}
          y2={20.385}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FB84E' />
          <stop offset={1} stopColor='#2A8640' />
        </linearGradient>
        <linearGradient
          id='prefix__paint2_linear_211_1040'
          x1={23.842}
          y1={0.333}
          x2={33.918}
          y2={-0.955}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FB84E' />
          <stop offset={1} stopColor='#2A8640' />
        </linearGradient>
        <filter
          id='prefix__filter0_bi_211_1040'
          x={-2.146}
          y={2.33}
          width={37.716}
          height={26.475}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation={2} />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_211_1040' />
          <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_211_1040' result='shape' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={0.2} dy={0.1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2={-1} k3={1} />
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0' />
          <feBlend in2='shape' result='effect2_innerShadow_211_1040' />
        </filter>
      </defs>
    </svg>
  ) : (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx={25.072} cy={51.679} r={6.32} fill='url(#prefix__paint0_linear_70_6791)' />
      <circle cx={42.575} cy={51.679} r={6.32} fill='url(#prefix__paint1_linear_70_6791)' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M49.332 15.858A3.16 3.16 0 0152.212 14h6.772a3.16 3.16 0 110 6.32H54.25l-3.449 7.623a3.16 3.16 0 01-5.759-2.605l4.29-9.48z'
        fill='url(#prefix__paint2_linear_70_6791)'
      />
      <g filter='url(#prefix__filter0_bi_70_6791)'>
        <path
          d='M11.32 28.013c-.667-2.537 1.246-5.018 3.869-5.018h37.755c2.623 0 4.536 2.481 3.868 5.018l-5.187 19.712a4 4 0 01-3.868 2.983h-27.38a4 4 0 01-3.869-2.983L11.32 28.013z'
          fill='#72BF44'
          fillOpacity={0.5}
        />
      </g>
      <defs>
        <linearGradient
          id='prefix__paint0_linear_70_6791'
          x1={18.752}
          y1={45.359}
          x2={29.775}
          y2={44.078}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FB84E' />
          <stop offset={1} stopColor='#2A8640' />
        </linearGradient>
        <linearGradient
          id='prefix__paint1_linear_70_6791'
          x1={36.255}
          y1={45.359}
          x2={47.278}
          y2={44.078}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FB84E' />
          <stop offset={1} stopColor='#2A8640' />
        </linearGradient>
        <linearGradient
          id='prefix__paint2_linear_70_6791'
          x1={44.762}
          y1={14}
          x2={59.876}
          y2={12.068}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FB84E' />
          <stop offset={1} stopColor='#2A8640' />
        </linearGradient>
        <filter
          id='prefix__filter0_bi_70_6791'
          x={7.187}
          y={18.995}
          width={53.76}
          height={35.713}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation={2} />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_70_6791' />
          <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_70_6791' result='shape' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={0.2} dy={0.1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2={-1} k3={1} />
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0' />
          <feBlend in2='shape' result='effect2_innerShadow_70_6791' />
        </filter>
      </defs>
    </svg>
  );
}

export default CartIcon;
