export const my_cards = {
  title: 'Мои карты',
  exit: 'Выход',
  selectors: {
    active: 'Активные',
    not_active: 'Закрытые'
  },
  card: {
    button: {
      sms: 'Номер карты и CVV',
      statement: 'Выписка'
    },
    rest: 'Остаток на'
  },
  tariffs: 'Тарифы предоплаченных карт',
  new: 'Купить ещё одну карту',
  no_not_active_card: 'У вас нет закрытых карт',
  no_active_card: 'У вас нет активных карт',

  statement: {
    title: 'Выписка',
    selectors: {
      last: 'Последние 10',
      all: 'Все'
    },
    rest: 'Остаток',
    no_transactions: 'По карте отстувуют транзакции'
  },
  sms_text: 'Мы отправили СМС с номером карты и CVV-кодом на номер {{mobile_phone}}'
};
