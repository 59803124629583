export const my_cards_kz = {
  title: 'Карталарым',
  exit: 'Шығу',
  selectors: {
    active: 'Белсенді',
    not_active: 'Жабылған'
  },
  card: {
    button: {
      sms: 'Карта нөмірі және CVV',
      statement: 'Көшірме'
    },
    rest: 'Қалдық'
  },
  tariffs: 'Алдын ала төленген карта тарифтері',
  new: 'Тағы бір картаны сатып алу',
  no_not_active_card: 'Сізде жабық карталар жоқ',
  no_active_card: 'Сізде белсенді карталар жоқ',

  statement: {
    title: 'Көшірме',
    selectors: {
      last: 'Соңғы 10',
      all: 'Барлығы'
    },
    rest: 'Қалдық',
    no_transactions: 'Карта бойынша транзакциялар жоқ'
  },
  sms_text: 'Біз {{mobile_phone}} нөміріне карта нөмірі мен CVV кодымен SMS жібердік'
};
