/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import 'twin.macro';

import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useState } from 'react';
import tw from 'twin.macro';

import ChevronDown from '@/assets/images/icons/ChevronDown';
import {NormalTitle} from '@/components';
function RevealingQAContainer({ qa }) {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div tw='flex mt-3 px-3 md:px-0 justify-between cursor-pointer' onClick={() => setOpen(!open)}>
        <NormalTitle text={qa.question} variant={'bold'} twStyle={[tw`mb-5 text-[16px] lg:text-[22px]`]} />
        <AnimatePresence>
          <motion.div
            animate={open ? { rotate: '180deg' } : { rotate: 0 }}
            tw='m-0 p-0 h-[25px]'
            transition={{ duration: 0.3 }}
          >
            <ChevronDown />
          </motion.div>
        </AnimatePresence>
      </div>
      <AnimatePresence>
        <motion.div
          initial={{ height: 0 }}
          tw='flex px-3 md:px-0 flex-col gap-5 mt-1 mb-3 text-secondary overflow-hidden'
          animate={open ? { height: 'auto', marginTop: '4px', marginBottom: '12px' } : { height: 0 }}
          transition={{ duration: 0.3 }}
        >
          {qa.answer}
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default RevealingQAContainer;
