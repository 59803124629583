import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getStep } from '@/app/store/slices/app';
import { pathnames } from '@/app/utils/constants';
import { wsStatuses } from '@/app/utils/ws-statuses';

export const useNavigateToTheStep = () => {
  const currentStep = useSelector(getStep);
  const navigate = useNavigate();

  const navigateToNewStep = () => {
    switch (currentStep) {
      case wsStatuses.products:
        navigate(pathnames.card_type);
      default:
        //navigate(pathnames.forensics)
        break;
    }
  };
  return [navigateToNewStep];
};
