import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import tw from 'twin.macro';

import { usePrepaidCreateOrderingMutation } from '@/app/api/my.documents-prepaid';
import PdfIcon from '@/assets/images/icons/PdfIcon';
import { BodyText, Modal, SubBody, Title } from '@/components';
import ModalPortal from '@/components/ui/modal/portal';
import { Selector2 } from '@/components/ui/selector2/selector2';
import { DeviceInfoContext } from '@/contexts/device-info-context';
import { convertPan } from '@/helper';

import TransactionList from './transaction-list';

const containerStyle = (bgColor, textColor) => {
  return {
    background: bgColor,
    color: textColor,
    width: '36px',
    fontWeight: 'bold',
    height: '24px',
    padding: '0 3px',
    marginRight: '20px',
    borderRadius: '2px',
    textAlign: 'center'
  };
};

const filterDates = data => {
  const dates = new Set(data?.map(item => new Date(item?.time).toLocaleDateString('en-CA')));
  return [...dates];
};

const filterTransactions = (date, data) => {
  if (data) {
    return data?.filter(item => {
      return new Date(item?.time).toLocaleDateString() === date.toLocaleDateString();
    });
  }
};

const returnCardType = product_code => {
  switch (product_code) {
    case 'PREPAID_50':
      return (
        <span
          css={containerStyle('linear-gradient(90deg, rgba(217,156,151,1) 0%, rgba(251,221,217,1) 100%)', '#C17772')}
        >
          50$
        </span>
      );
    case 'PREPAID_100':
      return <span></span>;
    case 'PREPAID_200':
      return <span></span>;
    default:
      break;
  }
};
function AccountStatementModal({
  open,
  setOpen,
  activeCard,
  options2,
  transactions,
  transactionsFetching,
  transactionsLoading
}) {
  const [createOrdering, { isLoading }] = usePrepaidCreateOrderingMutation();
  const { isMobile } = useContext(DeviceInfoContext);
  const [filter, setFilter] = useState(true);
  const [filteredTransaction, setFilteredTransactions] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (transactions && !(transactionsFetching || transactionsLoading)) {
      filter
        ? setFilteredTransactions([...transactions?.data]?.reverse().slice(0, 10))
        : setFilteredTransactions([...transactions?.data]?.reverse());
    }
  }, [filter, transactions, transactionsFetching, transactionsLoading]);
  return (
    <ModalPortal>
      <Modal
        open={open}
        setOpen={setOpen}
        twStyle={tw`rounded-2xl 2xl:w-[28vw] xl:w-[35vw] lg:w-[38vw] md:w-[60vw] w-[95vw] !p-5`}
      >
        {!(transactionsLoading || transactionsFetching) ? (
          <div tw='flex flex-col gap-5 max-h-[70vh] overflow-scroll px-3 my-3'>
            <Title text={t('my_cards.statement.title')} variant={'bold'} />
            <div tw='flex sm:flex-row flex-col gap-2 w-full justify-between'>
              <div tw='sm:block sm:text-left flex justify-between'>
                <BodyText text={`Карта №${activeCard?.number}`} twStyle={tw`text-secondary sm:w-full w-[30%] `} />
                <div tw='sm:mt-1 w-[70%] sm:w-auto text-left'>
                  {returnCardType(activeCard?.product_code)}
                  <BodyText text={convertPan(activeCard?.number_card)} twStyle={tw`inline-block`} />
                </div>
              </div>
              <div tw='flex sm:flex-col items-end justify-between'>
                <BodyText text={t('my_cards.statement.rest')} twStyle={tw`text-secondary w-[30%] sm:w-auto`} />
                <BodyText text={`${activeCard?.balance}$`} variant={'bold'} twStyle={tw`w-[70%] sm:w-auto`} />
              </div>
            </div>
            {transactions?.data?.length > 0 ? (
              <div tw='flex w-full justify-between gap-2 '>
                <Selector2
                  items={options2}
                  buttonStyle={tw`px-3 max-w-max`}
                  getActiveItem={setFilter}
                  defaultActiveItem={true}
                  twStyle={tw`max-w-max`}
                  small={isMobile}
                />
                <div
                  css={[isLoading && tw`opacity-60`]}
                  tw='bg-selector px-3 py-2 rounded-2xl text-green-secondary border border-green flex items-center'
                >
                  <button
                    tw='flex items-center gap-1'
                    onClick={() => createOrdering(activeCard?.uuid)}
                    disabled={isLoading}
                  >
                    <div tw='w-[32px] h-[32px]'>
                      {isLoading ? <ClipLoader size={30} speedMultiplier={0.6} color='#4f9d3a' /> : <PdfIcon />}
                    </div>
                    <BodyText text={t('my_cards.statement.title')} twStyle={tw`sm:inline hidden text-green`} />
                  </button>
                </div>
              </div>
            ) : (
              <SubBody text={t('my_cards.statement.no_transactions')} twStyle={tw`text-secondary`} />
            )}
            <div>
              <TransactionList
                filter={filter}
                transactions={filteredTransaction}
                dates={filterDates(filteredTransaction)}
                filterTransactions={filterTransactions}
              />
            </div>
          </div>
        ) : (
          <ClipLoader size={30} speedMultiplier={0.6} color='#4f9d3a' />
        )}
      </Modal>
    </ModalPortal>
  );
}

export default AccountStatementModal;
