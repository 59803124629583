import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { flow_open } from '@/app/utils/constants';
import ApplePayIcon from '@/assets/images/icons/ApplePayIcon';
import GarminPayIcon from '@/assets/images/icons/GarminPayIcon';
import GooglePayIcon from '@/assets/images/icons/GooglePayIcon';
import SamsungPayIcon from '@/assets/images/icons/SamsungPayIcon';
import TickIcon from '@/assets/images/icons/TickIcon';
import { BigTitle, Button, SubBody, SubTitle } from '@/components';

function CardBanner({ benefits, buttonText, cardImage, title, navigateUrl }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const payment = [
    {
      icon: <ApplePayIcon />,
      link: i18n.language.includes('kk')
        ? 'https://bankffin.kz/kk/page/kak-podkliucit-kartu-k-apple-pay-na-smartfone'
        : 'https://bankffin.kz/ru/page/kak-podkliucit-kartu-k-apple-pay-na-smartfone'
    },
    {
      icon: <GooglePayIcon />,
      link: i18n.language.includes('kk')
        ? 'https://bankffin.kz/kk/page/kak-podkliucit-kartu-k-google-pay-na-smartfone'
        : 'https://bankffin.kz/ru/page/kak-podkliucit-kartu-k-google-pay-na-smartfone'
    },
    {
      icon: <SamsungPayIcon />,
      link: i18n.language.includes('kk')
        ? 'https://bankffin.kz/kk/page/kak-podkliucit-kartu-k-samsung-pay-na-smartfone'
        : 'https://bankffin.kz/ru/page/kak-podkliucit-kartu-k-samsung-pay-na-smartfone'
    },
    {
      icon: <GarminPayIcon />,
      link: ''
    }
  ];

  return (
    <div className={'bg-accent-alpha sm:mr-16  rounded-br-[70px] sm:px-0 px-5'}>
      <div tw='flex pr-3 lg:left-[55px] sm:px-5 lg:px-0 relative max-w-app mx-auto items-center sm:items-end flex-col sm:flex-row  '>
        <div tw='flex flex-col justify-between  pt-10 sm:py-16 gap-7 min-w-[50%]'>
          <div tw='max-w-max'>
            <BigTitle text={title} twStyle={tw`text-[38px] lg:text-[64px] `} />
            <div tw='sm:py-5 flex flex-col lg:row-gap-5 row-gap-5 justify-between lg:mt-0 mt-5 '>
              {benefits?.map(item => (
                <div tw='flex gap-2 items-center' key={item}>
                  <TickIcon color={'var(--accent)'} />
                  <SubTitle text={item} />
                </div>
              ))}
            </div>
          </div>
          <div tw='flex  gap-1 '>
            {payment.map((item, idx) => (
              <a href={item.link} tw='' key={idx} target='_blank' rel='noreferrer'>
                <div tw='bg-[#ffff] py-3 px-2 h-[36px] flex justify-center items-center rounded-[8px] w-full'>
                  {item.icon}
                </div>
              </a>
            ))}
          </div>
          <div tw='sm:text-left text-center flex flex-col gap-3'>
            <Button
              variant='secondary'
              twStyle={tw`sm:max-w-[200px] text-center`}
              onClick={() => {
                navigate(`${navigateUrl}`);
              }}
              disabled={!flow_open}
            >
              {buttonText}
            </Button>
            {!flow_open && (
              <SubBody
                text={
                  i18n.language.includes('kk') ? (
                    <span>{import.meta.env.NR_CLOSED_TEXT_KK}</span>
                  ) : (
                    <span>{import.meta.env.NR_CLOSED_TEXT_RU}</span>
                  )
                }
                twStyle={tw`text-secondary`}
              />
            )}
          </div>
        </div>
        <div tw='flex flex-col gap-5 sm:w-[319px] 2xl:right-[0px] xl:right-[-30px] lg:right-[-20px] md:right-[0px] static md:w-auto md:min-w-[400px] items-center '>
          <img src={cardImage} alt='картинка' tw='' />
        </div>
      </div>
    </div>
  );
}

export default CardBanner;
