/* eslint-disable react/jsx-key */
import 'twin.macro';
export const pathnames = Object.freeze({
  landing: '/',
  apply: 'apply', //1
  verify: 'verify', //2
  card_type: 'card-type',
  waiting: 'waiting',
  success: 'success',
  error: 'error',
  my_documents_auth: 'my-documents-auth',
  my_documents: 'my-documents'
});

export const flow_open = Boolean(parseInt(import.meta.env.NR_FLOW_OPEN));
export const containerInfo = [
  {
    title: 'Банковским переводом (SWIFT)',
    caption: 'На свой счёт в долларах, евро или рублях',
    info: [
      'Перевод можно отправить в долларах, евро или рублях (из российского банка).',
      <span>
        Реквизиты ваших счетов доступны в мобильном приложении FFIN Bank KZ и в разделе{' '}
        <a href='/' tw='underline cursor-pointer'>
          “Мои документы”.
        </a>
      </span>,
      'Лимиты и комиссии — согласно тарифов банка-отправителя.',
      'Банк оставляет за собой право запросить документы, подтверждающие источник происхождения денег.'
    ]
  },
  {
    title: 'Переводом с карты Visa или Mastercard',
    caption: 'Подойдёт любая карта Visa или Mastercard, кроме выпущенных в РФ',
    info: [
      'Пополнить свою карту можно с любой карты Visa или Mastercard (кроме выпущенных в РФ) через мобильное приложение FFIN Bank KZ в разделе «Переводы» — «С карты на карту».',
      'Лимиты на переводы с карт Visa: на один перевод — 25 000 $, сумма переводов в сутки — 50 000 $, в неделю — 100 000 $, в месяц — 200 000 $ (эквивалент в валюте перевода).',
      'Лимиты на переводы с карт Mastercard: на один перевод — 5 000 $, сумма переводов в месяц — 10 000 $ (эквивалент в валюте перевода).',
      'У нас нет комиссий на пополнение с карт. Но комиссии и ограничения могут быть у банка, выпустившего карту отправителя.'
    ]
  },
  {
    title: 'Переводом с карты МИР',
    caption: 'С помощью сервиса Moneysend',
    info: [
      <span>
        Для перевода денег с карт МИР на карту Фридом Банка воспользуйтесь сервисом
        <a tw='underline cursor-pointer' href='https://moneysend.money' target='_blank' rel='noreferrer'>
          Moneysend.
        </a>
      </span>,
      'Лимиты и комиссии — согласно условий сервиса.',
      'Банк, выпустивший карту отправителя, может дополнительно удержать свою комиссию.'
    ]
  },
  {
    title: 'Со счёта Цифра Банка (РФ)',
    caption: 'В долларах, евро, рублях и тенге из мобильного приложения Цифра Банка',
    info: [
      <span>
        В мобильном приложении Цифра Банка переведите деньги по номеру телефона на свою карту или счёт, открытые в
        Казахстане. <a tw='underline cursor-pointer'>Подробная инструкция.</a>
      </span>,
      'Валюты перевода — доллары, евро, рубли и тенге.',
      'Лимиты и комиссии — согласно условий Цифра Банка.',
      <span>
        Заказать карту Цифра Банка можно <a tw='underline cursor-pointer'>онлайн</a> или{' '}
        <a tw='underline cursor-pointer'>в отделениях на территории РФ</a>, пополнить — через систему быстрых переводов
        из любого банка РФ.
      </span>
    ]
  },
  {
    title: 'Наличными в банкомате или отделении банка',
    caption: 'на территории Казахстана',
    info: [
      <span>
        Пополняйте счета в <a tw='underline cursor-pointer'>банкоматах и отделениях Фридом Банка</a> и{' '}
        <a tw='underline cursor-pointer'>кассах Фридом Мобайл</a> на территории Казахстана.
      </span>,
      'Банкоматы банка и кассы Фридом Мобайл принимают только тенге.',
      'В отделении банка принимают тенге, доллары, евро и рубли. При пополнении счёта рублями взимается комиссия 15% от суммы (минимум 5 000 тг).',
      'Других комиссий нет.',
      'Банк оставляет за собой право запросить документы, подтверждающие источник происхождения денег.'
    ]
  }
];

export const answersPrepaidFunctionRu = (prepaidCardLimit, activeTab) => {
  switch (activeTab) {
    case 1:
      return [
        {
          question: 'Что нужно для открытия карты?',
          answer: 'Только номер мобильного телефона для приёма SMS.'
        },
        {
          question: 'Можно ли открыть вторую карту?',
          answer: `На один номер телефона можно открыть до ${prepaidCardLimit} карт в месяц.`
        },
        {
          question: 'Как заказать пластиковую карту?',
          answer: 'Предоплаченные карты выпускаются только в цифровом виде.'
        },

        {
          question: 'Как активировать карту?',
          answer: 'Карта активируется автоматически.'
        },
        {
          question: 'Где узнать данные карты?',
          answer: 'Данные карты вы получите после её покупки. Часть — на сайте, часть — в SMS.'
        }
      ];
    case 2:
      return [
        {
          question: <span>Зачем нужен личный кабинет?</span>,
          answer: (
            <span>
              В{' '}
              <a href={`${import.meta.env.NR_HOMEPAGE}/my-documents`} tw='underline'>
                личном кабинете{' '}
              </a>
              отображаются карты с их балансом, выпиской и реквизитами.
            </span>
          )
        },
        {
          question: 'Когда я смогу начать пользоваться картой?',
          answer: 'Сразу после получения SMS с данными карты.'
        },
        {
          question: 'Как узнать баланс карты?',
          answer: 'В личном кабинете или в SMS, которое вы будете получать после каждой покупки или перевода.'
        },
        {
          question: 'Как узнать реквизиты карты?',
          answer: (
            <a href={`${import.meta.env.NR_HOMEPAGE}/my-documents`} tw='underline'>
              В личном кабинете.
            </a>
          )
        },

        {
          question: 'Конвертации',
          answer: (
            <span>
              Валюта карты — доллары США. Конвертация в валюту покупки будет происходить по{' '}
              <a href='https://bankffin.kz/ru/exchange-rates' tw='underline' target='_blank' rel='noreferrer'>
                обменному курсу
              </a>{' '}
              Фридом Банка.
            </span>
          )
        },
        {
          question: 'Как пополнить карту?',
          answer: 'Предоплаченные карты пополнять нельзя.'
        },
        {
          question: 'Что произойдёт с деньгами на карте, когда её срок действия закончится?',
          answer: 'Неиспользованная вами сумма на карте сгорает.'
        },
        {
          question: 'Как закрыть карту?',
          answer: 'Карта закроется автоматически, когда вы потратите все деньги или закончится срок её действия.'
        },
        {
          question: 'Как добавить карту в мобильный кошелёк?',
          answer: (
            <span>
              У нас есть инструкции для &nbsp;
              <a
                href='https://bankffin.kz/ru/page/kak-podkliucit-kartu-k-apple-pay-na-smartfone'
                target='_blank'
                rel='noreferrer'
                tw='underline'
              >
                Apple Pay,
              </a>
              &nbsp;
              <a
                href='https://bankffin.kz/ru/page/kak-podkliucit-kartu-k-samsung-pay-na-smartfone'
                target='_blank'
                rel='noreferrer'
                tw='underline'
              >
                Samsung Pay,
              </a>
              &nbsp;
              <a
                href=' https://bankffin.kz/ru/page/kak-podkliucit-kartu-k-google-pay-na-smartfone'
                target='_blank'
                rel='noreferrer'
                tw='underline'
              >
                Google Pay.
              </a>
            </span>
          )
        },
        {
          question: 'Как перевести деньги с предоплаченной на другую карту?',
          // answer: <span>С помощью сервиса перевода с карты на карту</span>
          answer: 'Переводить с предоплаченной карты нельзя'
        },
        {
          question: 'Как снять наличные с карты?',
          answer: 'Снять деньги с предоплаченной карты нельзя.'
        }
      ];
    case 3:
      return [
        {
          question:
            'Нужно ли уведомлять налоговые органы своей страны об открытии предоплаченной карты в иностранном банке?',
          answer:
            'Предоплаченные карты не предполагают открытия полноценного банковского счёта. Поэтому уведомлять налоговые органы об открытии такой карты не нужно.'
        }
      ];
    case 4:
      return [
        {
          question: 'Я потерял данные карты, что делать?',
          answer:
            'К сожалению, восстановить данные карты, перевыпустить её или вернуть вам оставшиеся деньги невозможно.'
        },
        {
          question: 'Не пришла SMS с данными карты ',
          answer: (
            <span>
              Обратитесь в службу заботы о пользователях: <br /> 8 800 555 89 90 (для звонков) <br /> +7 776 0 595 595
              (whatsapp для клиентов за рубежом) <br /> salem@bankffin.kz
            </span>
          )
        },
        {
          question: 'Как связаться со службой поддержки?',
          answer: (
            <span>
              8 800 555 89 90 (для звонков) <br /> +7 776 0 595 595 (whatsapp для клиентов за рубежом) <br />{' '}
              salem@bankffin.kz
            </span>
          )
        }
      ];
    default:
      break;
  }
};

export const answersPrepaid_ru = [[], [], [], []];

export const answersPrepaidFunctionKz = (prepaidCardLimit, activeTab) => {
  switch (activeTab) {
    case 1:
      return [
        {
          question: 'Картаны ашу үшін не қажет?',
          answer: 'Тек SMS қабылдауға арналған ұялы телефон нөмірі.'
        },
        {
          question: 'Екінші картаны ашуға бола ма?',
          answer: `Айына бір телефон нөміріне ${prepaidCardLimit} картаға дейін ашуға болады.`
        },

        {
          question: 'Пластикалық картаға қалай тапсырыс беруге болады?',
          answer: 'Алдын ала төленген карталар тек цифрлық түрде шығарылады.'
        },
        {
          question: 'Алдын ала төленген картаны қалай сыйға беруге болады?',
          answer:
            'Алдын ала төленген карта сатып алған кезде көрсетілген телефон нөміріне бекітіледі. Сол нөмірге карта деректерінің бір бөлігі бар SMS келеді (жарамдылық мерзімі, CVV және PIN коды). \n Егер сіз сатып алу процесінде басқа адамның телефон нөмірін көрсетсеңіз, сатып алуды аяқтау үшін осы нөмірден растау кодын алуға дайын болыңыз.'
        },
        {
          question: 'Картаны қалай белсендіруге болады?',
          answer: 'Карта автоматты түрде іске қосылады.'
        },
        {
          question: 'Карта деректерін қайдан табуға болады?',
          answer: 'Сіз деректерді картаны сатып алғаннан кейін аласыз. Бір бөлігін — сайттан, бір бөлігін — SMS-тен.'
        }
      ];
    case 2:
      return [
        {
          question: <span>Жеке кабинет не үшін қажет?</span>,
          answer: (
            <span>
              {' '}
              <a href={`${import.meta.env.NR_HOMEPAGE}/my-documents`} tw='underline'>
                {' '}
                Жеке кабинетте{' '}
              </a>{' '}
              карталардың балансы, үзінді көшірмелері және деректемелері көрсетілген.
            </span>
          )
        },
        {
          question: 'Картаны қашан пайдалануға болады?',
          answer: 'Карта деректері бар SMS алғаннан кейін бірден.'
        },
        {
          question: 'Картаның балансын қалай білуге болады?',
          answer: 'Жеке кабинеттен немесе әрбір сатып алудан немесе аударымнан кейін келетін SMS арқылы.'
        },
        {
          question: 'Айырбастау',
          answer: (
            <span>
              Карта валютасы — АҚШ доллары. Сатып алу валютасына айырбастау Фридом Банктің{' '}
              <a href='https://bankffin.kz/kk/exchange-rates' target='_blank' rel='noreferrer' tw='underline'>
                айырбастау бағамы
              </a>{' '}
              бойынша жүзеге асырылады.
            </span>
          )
        },
        {
          question: 'Карта деректемелерін қалай білуге болады?',
          answer: (
            <a href={`${import.meta.env.NR_HOMEPAGE}/my-documents`} tw='underline'>
              Жеке кабинетте
            </a>
          )
        },
        {
          question: 'Картаны қалай толтыруға болады?',
          answer: 'Алдын ала төленген карталарды толтыру мүмкін емес.'
        },
        {
          question: 'Картаның мерзімі аяқталған кезде ақшаға не болады?',
          answer: 'Картаңыздағы пайдаланылмаған сома «күйіп кетеді»'
        },
        {
          question: 'Картаны қалай жабуға болады?',
          answer:
            'Жабуға болмайды. Ақшаны толығымен жұмсаған кезде немесе оның мерзімі аяқталған кезде карта автоматты түрде жабылады.'
        },
        {
          question: 'Картаны мобильді әмиянға қалай қосуға болады?',
          answer: (
            <span>
              Бізде &nbsp;
              <a
                href='https://bankffin.kz/kk/page/kak-podkliucit-kartu-k-apple-pay-na-smartfone'
                tw='underline'
                target='_blank'
                rel='noreferrer'
              >
                Apple Pay,
              </a>
              &nbsp;
              <a
                href=' https://bankffin.kz/kk/page/kak-podkliucit-kartu-k-samsung-pay-na-smartfone'
                tw='underline'
                target='_blank'
                rel='noreferrer'
              >
                Samsung Pay
              </a>
              &nbsp; және &nbsp;
              <a
                href='https://bankffin.kz/kk/page/kak-podkliucit-kartu-k-google-pay-na-smartfone'
                tw='underline'
                target='_blank'
                rel='noreferrer'
              >
                Google Pay
              </a>
              &nbsp; нұсқаулықтарымыз бар
            </span>
          )
        },
        {
          question: 'Ақшаны алдын ала төленген картадан басқа картаға қалай аударуға болады?',
          // answer: 'Картадан картаға аудару қызметінің көмегімен.'
          answer: ' Алдын ала төленген картадан аударымдар мүмкін емес'
        },
        {
          question: 'Картадан қолма-қол ақшаны қалай шешіп алуға болады?',
          answer: 'Алдын ала төленген картадан ақша шешіп алу мүмкін емес.'
        }
      ];
    case 3:
      return [
        {
          question:
            'Шетелдік банкте алдын ала төленген картаны ашу туралы өз елінің салық органдарына хабарлау керек пе?',
          answer:
            'Алдын ала төленген карталар толымды банктік шот ашылғанын білідірмейді. Сондықтан мұндай картаның ашылғаны туралы салық органдарына хабарлаудың қажеті жоқ.'
        }
      ];
    case 4:
      return [
        {
          question: 'Мен карта деректерін жоғалттым, не істеу керек?',
          answer:
            'Өкінішке қарай, карта деректерін қалпына келтіру, оны қайта шығару немесе қалған ақшаны қайтару мүмкін емес.'
        },
        {
          question: 'Карта деректері бар SMS келген жоқ',
          answer:
            'Пайдаланушыларға қамқорлық көрсету қызметіне хабарласыңыз: \n 8 800 555 89 90 (қоңыраулар үшін) \n 7 776 0 595 595 (шетелдегі клиенттерге арналған whatsapp) \n salem@bankffin.kz'
        },
        {
          question: 'Қолдау қызметіне қалай хабарласуға болады?',
          answer:
            '8 800 555 89 90 (қоңыраулар үшін) \n 7 776 0 595 595 (шетелдегі клиенттерге арналған whatsapp) \n salem@bankffin.kz'
        }
      ];
    default:
      break;
  }
};

export const answersPrepaid_kz = [[], [], [], []];
