import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getStep, setNewStep } from '@/app/store/slices/app';
import { setCustomError, setError } from '@/app/store/slices/error';
import { pathnames } from '@/app/utils/constants';
import { wsStatuses } from '@/app/utils/ws-statuses';

const WebSocketComponent = ({ access_token, application_uuid, children }) => {
  const [wsConnected, setWsConnected] = useState(false);
  const [, setTimeoutId] = useState(undefined);
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const isMounted = useRef(true);
  const step = useSelector(getStep);
  const navigate = useNavigate();

  const writeNewStep = step => {
    dispatch(setNewStep(`${step}`));
  };

  console.log('application_uuid', application_uuid);
  const connectWebSocket = () => {
    const socket = new WebSocket(`wss://non-resident-ws.bankffin.kz/ws/${application_uuid}?auth_token=${access_token}`);

    socket.addEventListener('open', event => {
      setWsConnected(true);
      console.log('WebSocket connection opened:', event);
    });

    socket.addEventListener('message', event => {
      console.log('Message from server:', event.data);
      const message = JSON.parse(event.data);

      if (message.message === wsStatuses.error) {
        console.log('message run to an error', message.reason);
        dispatch(setCustomError({ code: message.message, description: message.reason }));
      } else {
        writeNewStep(message.message);
      }
    });

    socket.addEventListener('close', event => {
      console.log('WebSocket connection closed:', event);
      setWsConnected(false);
      // Set a timeout to reconnect after 3 seconds if the socket is closed
      setTimeoutId(setTimeout(connectWebSocket, 2000));
    });

    socketRef.current = socket;
  };

  useEffect(() => {
    if (access_token && application_uuid && isMounted.current) {
      connectWebSocket();
    }

    return () => {
      if (socketRef.current?.readyState === 1) {
        socketRef.current.close();
      }
    };
  }, [access_token, application_uuid]);

  useEffect(() => {
    if (wsConnected && !step) {
      navigate(pathnames.waiting);
    }
  }, [wsConnected, step]);

  // Use the useEffect cleanup to update the isMounted ref when the component unmounts
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return <></>;
};

export default WebSocketComponent;
