import React from 'react';
import Div100vh from 'react-div-100vh';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { isWorld } from '@/app/api/API-Url';
import { getCardType } from '@/app/store/slices/app';
import { clearError } from '@/app/store/slices/error';
import BigExclamationIcon from '@/assets/images/icons/BigExclamationIcon';
import { BigTitle, Button, SubBody, Wrapper } from '@/components';
import useCheckStatusPrepaid from '@/helper/hooks/useCheckStatusPrepaid';

const Page503 = ({ error, resetErrorBoundary }) => {
  const dispatch = useDispatch();
  const apiUrl = isWorld ? import.meta.env.NR_HOMEPAGE_WORLD : import.meta.env.NR_HOMEPAGE;
  const card_type = useSelector(getCardType);
  const [checkStatusPrepaid] = useCheckStatusPrepaid();

  const goToMainPage = () => {
    if (card_type === 'prepaid-card') {
      checkStatusPrepaid();
      resetErrorBoundary();
      dispatch(clearError());
    } else {
      resetErrorBoundary();
      dispatch(clearError());
      window.open(apiUrl, '_self');
    }
  };

  console.log('error', error);
  return (
    <Div100vh>
      <Wrapper twStyles={tw`h-screen flex flex-col justify-center items-center -translate-y-20 pt-7 sm:pt-0`}>
        <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-yellow-alpha20 mt-[48px]'>
          <BigExclamationIcon />
        </div>

        <div tw='pt-5 text-center flex flex-col space-y-2'>
          <BigTitle text={'Ведутся технические работы'} />
          <SubBody
            text={
              error
                ? 'По техническим причинам сервис временно недоступен. Пожалуйста, попробуйте позднее'
                : 'Просим прощения за неудобства, но в данный момент по техническим причинам сервис недоуступен. Пожалуйста, попробуйте позднее'
            }
            twStyle={tw`text-center text-secondary lg:max-w-[512px]`}
          />
        </div>
        <div tw='pt-9'>
          <Button variant='ghost' twStyle={tw`w-[350px]`} onClick={goToMainPage}>
            Повторить
          </Button>
        </div>
      </Wrapper>
    </Div100vh>
  );
};
export default Page503;
