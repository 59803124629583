import React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import CartIcon from '@/assets/images/icons/CartIcon';
import DamageIcon from '@/assets/images/icons/DamageIcon';
import SecurityIcon from '@/assets/images/icons/SecurityIcon';
import SmsIcon from '@/assets/images/icons/SmsIcon';
import socialMediaIcons from '@/assets/images/social-media.svg';
import { BodyText, SubTitle } from '@/components';
import SectionBlock from '@/components/ui/section-block/section-block';

function LandingPrepaidHowWorks() {
  const { t } = useTranslation();
  const cardInfo = useMemo(
    () => [
      { icon: <CartIcon />, caption: t('landing.howItWorks.benefits.benefit1') },
      { icon: <SecurityIcon />, caption: t('landing.howItWorks.benefits.benefit2') },
      { icon: <SmsIcon />, caption: t('landing.howItWorks.benefits.benefit3') },
      { icon: <DamageIcon />, caption: t('landing.howItWorks.benefits.benefit4') }
    ],
    [t]
  );
  return (
    <SectionBlock title={t('landing.howItWorks.title')}>
      <SubTitle
        text={
          <span>
            {t('landing.howItWorks.subtitle1')}
            <img src={socialMediaIcons} alt='social media' tw='inline w-[150px]' /> .{t('landing.howItWorks.subtitle2')}
          </span>
        }
        twStyle={tw`lg:w-[90%]`}
      />
      <div tw='mt-10 grid sm:grid-cols-2 grid-cols-1  lg:grid-cols-4 gap-5'>
        {cardInfo.map(card => (
          <div tw='bg-secondary rounded-[20px] p-5 pt-2 flex flex-col sm:gap-2 gap-0' key={card.caption}>
            <div tw='h-[80px] flex items-center '>{card.icon}</div>
            <BodyText text={card.caption} twStyle={tw``} />
          </div>
        ))}
      </div>
    </SectionBlock>
  );
}

export default LandingPrepaidHowWorks;
