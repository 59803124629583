import 'twin.macro';

import React from 'react';
import { useContext } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { pathnames } from '@/app/utils/constants';
import { ThemeContext } from '@/contexts/theme-context';
import Footer from '@/views/footer';

import { MainHeader } from '../headers/main-header';

const AppLayout = () => {
  const { theme } = useContext(ThemeContext);
  const isMyDocuments = useLocation().pathname.replaceAll('/', '') === pathnames.my_documents;
  return (
    <div
      tw='flex flex-col min-h-[100vh] h-full justify-between bg-primary'
      // css={[isMyDocuments && theme === 'light' && tw`bg-primary`]}
    >
      <MainHeader />
      <Outlet />
      <Footer />
    </div>
  );
};

export { AppLayout };
