import * as React from 'react';

function SecurityIcon(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M37.278 49.008c3.998-1.598 17.258-8.544 17.86-30.568a2.738 2.738 0 00-1.295-2.384 33.495 33.495 0 00-35.391 0 2.738 2.738 0 00-1.296 2.384c.603 22.024 13.863 28.97 17.86 30.568a3.014 3.014 0 002.262 0z'
        fill='url(#prefix__paint0_linear_70_6803)'
      />
      <g filter='url(#prefix__filter0_bi_70_6803)'>
        <path
          d='M37.188 60.821c4.27-1.5 20.252-8.695 21.105-32.522.044-1.232-.625-2.383-1.695-2.995a41.219 41.219 0 00-40.9 0c-1.071.612-1.74 1.763-1.696 2.995.853 23.827 16.835 31.022 21.104 32.522.681.24 1.401.24 2.082 0z'
          fill='#72BF44'
          fillOpacity={0.5}
        />
      </g>
      <path
        d='M34.642 40.904a.2.2 0 00.28 0l7.404-7.403a1.67 1.67 0 012.362 2.363l-8.724 8.725a1.67 1.67 0 01-2.363 0l-4.986-4.986a1.67 1.67 0 112.363-2.363l3.663 3.664z'
        fill='#fff'
        fillOpacity={0.9}
        stroke='url(#prefix__paint1_linear_70_6803)'
        strokeWidth={0.398}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <linearGradient
          id='prefix__paint0_linear_70_6803'
          x1={17.155}
          y1={11}
          x2={50.282}
          y2={7.174}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FB84E' />
          <stop offset={1} stopColor='#2A8640' />
        </linearGradient>
        <linearGradient
          id='prefix__paint1_linear_70_6803'
          x1={28.423}
          y1={34.591}
          x2={39.577}
          y2={38.309}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset={1} stopColor='#fff' stopOpacity={0} />
        </linearGradient>
        <filter
          id='prefix__filter0_bi_70_6803'
          x={10}
          y={15.873}
          width={52.295}
          height={49.127}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation={2} />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_70_6803' />
          <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_70_6803' result='shape' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={0.2} dy={0.1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2={-1} k3={1} />
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0' />
          <feBlend in2='shape' result='effect2_innerShadow_70_6803' />
        </filter>
      </defs>
    </svg>
  );
}

export default SecurityIcon;
