import 'twin.macro';

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import BigLogo from '@/assets/images/icons/BigLogo';
import PhoneIcon from '@/assets/images/icons/PhoneIcon';
import WhatsappIcon from '@/assets/images/icons/WhatsappIcon';
import { BodyText, Caption, ContactItem } from '@/components';
import { DeviceInfoContext } from '@/contexts/device-info-context';
import { ThemeContext } from '@/contexts/theme-context';

import SocialMedias from './social-medias';
function Footer({ landing = true }) {
  const { isMobile } = useContext(DeviceInfoContext);
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  return (
    <div
      tw='py-10  sm:py-10 pb-5 lg:px-0 px-5 border-t border-grey transition-all'
      css={[(!landing || theme === 'dark') && tw`bg-secondary`]}
    >
      <div tw='max-w-app mx-auto'>
        <div tw='border-b border-grey'>
          <div tw='flex justify-between pb-20'>
            <BigLogo width='200' height='80' />
            <div>
              <div tw='flex gap-2 sm:gap-10'>
                {!isMobile && (
                  <>
                    <ContactItem
                      icon={<WhatsappIcon />}
                      text='+7 776 1 595 595'
                      caption={'WHATSAPP'}
                      iconStyle={tw`bg-primary`}
                      link='https://wa.me/77760595595'
                    />

                    <ContactItem
                      icon={<PhoneIcon />}
                      link='tel:595'
                      text='595'
                      caption={t('landing.footer.free_call')}
                      iconStyle={tw`bg-primary `}
                    />
                  </>
                )}
                <SocialMedias />
              </div>
            </div>
          </div>
          {isMobile && (
            <div tw='flex flex-col items-center justify-between mb-5 gap-5'>
              <div className={'flex justify-between w-full'}>
                <a href='https://wa.me/77760595595'>
                  <div tw='flex flex-col items-center gap-1'>
                    <BodyText text={'+7 776 1 595 595'} twStyle={tw`text-s16 font-bold`} />
                    <Caption text={'WHATSAPP'} twStyle={tw` text-inactive`} />
                  </div>
                </a>
                <a href='tel:595'>
                  <div tw='flex flex-col items-center gap-1'>
                    <BodyText text={'595'} twStyle={tw`text-s16 font-bold`} />
                    <Caption text={t('landing.footer.free_call')} twStyle={tw` text-inactive`} />
                  </div>
                </a>
              </div>
            </div>
          )}
        </div>

        <Caption
          text={t('landing.footer.license')}
          twStyle={tw`text-inactive mt-5 sm:text-right text-center uppercase`}
        />
      </div>
    </div>
  );
}

export default Footer;
