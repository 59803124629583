import * as React from 'react';
import { useContext } from 'react';

import { DeviceInfoContext } from '@/contexts/device-info-context';

function BucketIcon(props) {
  const { isMobile } = useContext(DeviceInfoContext);
  return (
    <svg width={isMobile ? 35 : 25} height={25} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M9.31 2L5.69 5.63M15.69 2l3.62 3.63'
        stroke='#70FF6E'
        strokeWidth={1.5}
        strokeMiterlimit={10}
        fill='none'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 7.85c0-1.85.99-2 2.22-2h15.56c1.23 0 2.22.15 2.22 2 0 2.15-.99 2-2.22 2H4.72c-1.23 0-2.22.15-2.22-2z'
        stroke='#70FF6E'
        fill='none'
        strokeWidth={1.5}
      />
      <path
        d='M10.26 14v3.55M14.86 14v3.55M4 10l1.41 8.64C5.73 20.58 6.5 22 9.36 22h6.03c3.11 0 3.57-1.36 3.93-3.24L21 10'
        stroke='#70FF6E'
        strokeWidth={1.5}
        fill='none'
        strokeLinecap='round'
      />
    </svg>
  );
}

export default BucketIcon;
