import 'twin.macro';

import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import tw from 'twin.macro';

import { getIsAuth } from '@/app/store/slices/auth';
import { clearCustomError } from '@/app/store/slices/error';
import Logo from '@/assets/images/Logo';
import { AppWrapper, ContactItem } from '@/components';
import { ThemeContext } from '@/contexts/theme-context';
import ThemeToggle from '@/views/theme-toggle';

import PhoneIcon from '../../assets/images/icons/PhoneIcon';
import WhatsappIcon from '../../assets/images/icons/WhatsappIcon';
import AuthModal from '../auth-modal';
import LangToggle from '../lang-toggle';

const MainHeader = () => {
  const [open, setOpen] = useState(false);
  const { i18n } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const lang = i18n.language;
  const isAuth = useSelector(getIsAuth);
  const dispatch = useDispatch();
  const handleClearError = () => {
    dispatch(clearCustomError());
  };

  const handleChangeLanguage = () => {
    const changeLang = i18n.language.includes('ru') ? 'kk' : 'ru';
    i18n.changeLanguage(changeLang);
    document.cookie = `django_language=${changeLang}; max-age=300; path=/;`;
  };

  useEffect(() => {
    if (document.cookie) {
      document.cookie = 'django_language=; expires= Thu, 01 Jan 1970 00:00:00 UTC; path=/';
    }
    if (lang.includes('ru')) {
      document.cookie = `django_language=ru; max-age=300; path=/;`;
    } else {
      document.cookie = `django_language=kk; max-age=300; path=/; `;
    }
  }, []);

  return (
    <>
      <div
        tw='border border-grey border-l-0 border-r-0 border-t-0 bg-primary'
        css={[theme === 'light' && tw`bg-secondary`]}
      >
        <AppWrapper>
          <a href='/' onClick={handleClearError}>
            <Logo tw='flex-1' />
          </a>

          <div tw='flex gap-4 mr-3 ml-auto '>
            <ContactItem
              icon={<WhatsappIcon />}
              link='https://wa.me/77760595595'
              text={'+7 776 0 595 595'}
              twStyle={tw`min-w-[110px]`}
            />
            <ContactItem icon={<PhoneIcon />} link='tel:595' text={'595'} />
            {!isAuth && <AuthModal open={open} setOpen={setOpen} />}
            <LangToggle handleChangeLanguage={handleChangeLanguage} />
            <ThemeToggle />
            {/* {!isAuth && !location.includes('prepaid') && (
              <button onClick={() => setAuthOpen(true)} tw='!min-w-[32px] max-w-[90px] !p-0 !m-0'>
                <div tw='sm:h-[32px] h-[28px] sm:w-auto w-[28px] sm:px-2 flex items-center justify-center bg-button rounded-full gap-1 '>
                  <SignInIcon />
                  {!isMobile && <BodyText text={'Войти'} twStyle={tw`text-s14 text-white`} />}
                </div>
              </button>
            )} */}
          </div>
        </AppWrapper>
      </div>
    </>
  );
};

export { MainHeader };
