/* eslint-disable react/jsx-key */
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { prepaid_kz } from './kz/landing';
import { my_cards_kz } from './kz/my-cards';
import {
  apply_kz,
  buttons_kz,
  choice_product_kz,
  error_descriptions_kz,
  success_kz,
  verify_kz,
  waiting_kz
} from './kz/steps';
import { prepaid } from './ru/landing';
import { my_cards } from './ru/my-cards';
import { apply, buttons, choice_product, error_descriptions, success, verify, waiting } from './ru/steps';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'ru',
    resources: {
      ru: {
        translation: {
          landing: {
            header: {
              signIn: 'Войти'
            },
            banner: prepaid.banner,
            howItWorks: prepaid.hiw,
            conditions: prepaid.conditions,
            qa: prepaid.qa,
            footer: {
              free_call: 'Бесплатный звонок',
              license: '© 2024, АО «ФРИДОМ БАНК КАЗАХСТАН». ЛИЦЕНЗИЯ АРРФР №1.2.108/43/250 ОТ 01.02.2023'
            }
          },
          auth: {
            title: 'Вход для клиентов',
            get_sms: 'Получить SMS-код',
            mobile_phone: 'Телефон',
            resend_after: 'Отправить повторно через',
            resend: 'Отправить повторно'
          },
          my_cards: my_cards,
          steps: {
            apply: apply,
            verify: verify,
            choice_product: choice_product,
            success: success,
            waiting: waiting,
            errors: {
              neccessary: 'Это поле обязательное',
              mobile_phone: 'Мобильный номер должен состоять из {{number}} цифр',
              code_4: 'Код должен состоять из 4 цифр'
            },
            err_desc: error_descriptions,
            buttons: buttons
          }
        }
      },
      kk: {
        translation: {
          landing: {
            header: {
              signIn: 'Кіру'
            },
            banner: prepaid_kz.banner,
            howItWorks: prepaid_kz.hiw,
            conditions: prepaid_kz.conditions,
            qa: prepaid_kz.qa,
            footer: {
              free_call: 'ТЕГІН ҚОҢЫРАУ ШАЛУ',
              license: '© 2024, "ФРИДОМ БАНК ҚАЗАҚСТАН" 01.02.2023 БЕРІЛГЕН № 1.2.108/43/250 ҚНРДА ЛИЦЕНЗИЯСЫ'
            }
          },
          auth: {
            title: 'Клиенттер үшін кіру',
            get_sms: 'SMS-код алу',
            mobile_phone: 'Телефон',
            resend_after: 'сек кейін қайта жіберу',
            resend: 'Қайта жіберу'
          },
          my_cards: my_cards_kz,
          steps: {
            apply: apply_kz,
            verify: verify_kz,
            choice_product: choice_product_kz,
            success: success_kz,
            waiting: waiting_kz,
            errors: {
              neccessary: 'Бұл өріс міндетті',
              mobile_phone: 'Ұялы нөмір {{number}} саннан тұруы қажет',
              code_4: 'Код 4 саннан тұруы қажет'
            },
            err_desc: error_descriptions_kz,
            buttons: buttons_kz
          }
        }
      }
    }
  });
