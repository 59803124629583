import 'twin.macro';

import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import tw from 'twin.macro';

import { setPage } from '@/app/store/slices/cards';
import ChevronDown from '@/assets/images/icons/ChevronDown';

export default function TablePagination({ dataCount, filter }) {
  const [itemOffset, setItemOffset] = useState(filter?.offset);
  const [initialPage, setInitialPage] = useState(0);
  const dispatch = useDispatch();

  const endOffset = itemOffset + filter?.limit;

  const pageCount = Math.ceil(dataCount / filter?.limit);

  const handlePageClick = event => {
    const newOffset = (event.selected * filter?.limit) % dataCount;
    dispatch(setPage({ offset: newOffset }));
  };

  return (
    <div tw='flex mt-3 mb-5 justify-end w-[100%]'>
      <div tw='flex items-center justify-center'>
        <ReactPaginate
          className='pagination'
          breakLabel='...'
          nextLabel={pageCount > 1 && <Arrow twStyle={tw`rotate-[270deg]`} />}
          onPageChange={handlePageClick}
          pageCount={pageCount}
          previousLabel={pageCount > 1 && <Arrow twStyle={tw`rotate-[90deg]`} />}
        />
      </div>
    </div>
  );
}

const Arrow = ({ twStyle }) => {
  return (
    <div css={[twStyle]} tw=''>
      <ChevronDown />
    </div>
  );
};
