import { split } from 'ramda';
import React from 'react';
import { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { useShortLinkDecodeMutation } from './app/api/prepaid';
import { getStep } from './app/store/slices/app';
import { setAuth } from './app/store/slices/auth';
import { getCustomErrors, getErrors } from './app/store/slices/error';
import { flow_open, pathnames } from './app/utils/constants';
import useCheckStatusPrepaid from './helper/hooks/useCheckStatusPrepaid';
import { useNavigateToTheStep } from './helper/hooks/useNavigateToTheStep';
import { RequireAuth } from './helper/RequireAuth';
import ErrorPage from './pages/error-page';
import LandingPrepaid from './pages/landing-prepaid';
import MyDocumentsMainProfile from './pages/my-documents/my-documents-profile';
import PrepaidSteps from './pages/steps';
import { AppLayout } from './views/layouts/app-layout';

if (import.meta.env.PROD) {
  console.log = () => {};
}

function App() {
  const customError = useSelector(getCustomErrors);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const error = useSelector(getErrors);
  const step = useSelector(getStep);

  useErrorHandler(error);

  const [navigateToNewStep] = useNavigateToTheStep();
  const [checkStatusPrepaid] = useCheckStatusPrepaid();

  const [fetchShortLinkDecode, { data: shortLinkDecodeData, error: shortLinkDecodeError }] =
    useShortLinkDecodeMutation();

  useEffect(() => {
    if (customError?.code && customError?.code !== 'AUTHORIZED_PERSONAL_LINK') {
      navigate('/error');
    }
  }, [customError?.code, navigate]);

  useEffect(() => {
    if (split('/', location.pathname)[1] === 's') {
      fetchShortLinkDecode({ token: split('/', location.pathname)[2] })
        .unwrap()
        .then(data => {
          dispatch(setAuth({ access: data?.data?.access, refresh: data?.data?.refresh }));
          checkStatusPrepaid();
        });
    }
  }, [location.pathname, dispatch, checkStatusPrepaid, fetchShortLinkDecode]);

  useEffect(() => {
    step && navigateToNewStep();
  }, [step]);

  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route index element={<LandingPrepaid />} />

        {/* <Route path={pathnames.my_documents_auth} element={<MyDocumentsAuth />} /> */}
        <Route
          path={pathnames.my_documents}
          element={
            <RequireAuth>
              <MyDocumentsMainProfile />
            </RequireAuth>
          }
        />
      </Route>
      {
        <Route path={pathnames.landing} element={<AppLayout />}>
          <Route path={'*'} element={<PrepaidSteps />} />
          <Route path={pathnames.error} element={<ErrorPage />} />
        </Route>
      }
    </Routes>
  );
}

export default App;
