import { api } from '.';
import { APIUrl } from './API-Url';

export const prepaidCardApi = api.injectEndpoints({
  endpoints: build => ({
    prepaidApply: build.mutation({
      query: mobile_phone => ({
        url: `${APIUrl}/prepaid/orchestrator/application/apply/`,
        method: 'POST',
        body: mobile_phone
      })
    }),
    prepaidCancel: build.mutation({
      query: () => ({
        url: `${APIUrl}/prepaid/application/cancel/`,
        method: 'POST'
      })
    }),
    prepaidOtpVerify: build.mutation({
      query: ({ mobile_phone, code }) => ({
        url: `${APIUrl}/prepaid/orchestrator/application/otp-verify/`,
        method: 'POST',
        body: { mobile_phone, code }
      })
    }),
    prepaidGetStatus: build.query({
      query: () => ({
        url: `${APIUrl}/prepaid/application/status/`,
        method: 'GET'
      })
    }),
    prepaidGetProducts: build.query({
      query: () => ({
        url: `${APIUrl}/prepaid/products/`,
        method: 'GET'
      })
    }),
    prepaidOrderCard: build.mutation({
      query: product_code => {
        return { url: `${APIUrl}/prepaid/orchestrator/products/order/`, method: 'POST', body: product_code };
      }
    }),
    prepaidCountries: build.query({
      query: () => {
        return { url: `${APIUrl}/prepaid/countries/`, method: 'GET' };
      }
    }),
    shortLinkDecode: build.mutation({
      query: ({ token }) => ({
        url: `${APIUrl}/short-link/decode/`,
        method: 'POST',
        body: { token }
      })
      //   transformResponse: response => response.data
    }),
    getPaymentUrl: build.query({
      query: () => ({
        url: `${APIUrl}/prepaid/application/payment-url/`
      })
    })
  })
});

export const {
  usePrepaidApplyMutation,
  usePrepaidCancelMutation,
  usePrepaidGetProductsQuery,
  usePrepaidGetStatusQuery,
  usePrepaidOrderCardMutation,
  usePrepaidOtpVerifyMutation,
  usePrepaidCountriesQuery,
  useShortLinkDecodeMutation,
  useGetPaymentUrlQuery
} = prepaidCardApi;
