import React from 'react';

function BigLogo() {
  return (
    <div>
      <svg xmlns='http://www.w3.org/2000/svg' width='155' height='47' viewBox='0 0 155 47' fill='none'>
        <path
          d='M20.1671 47C20.1671 47 0 41.0173 0 11.3571V0H40.3342V11.3571C40.3342 39.9018 20.1671 47 20.1671 47Z'
          fill='#4FB84E'
        />
        <path
          d='M31.8342 11.4585H15.5898V20.8889H31.8342V23.7282C31.8342 28.4941 30.4763 32.3474 23.3348 32.3474H15.5898V44.7692C18.2553 46.4423 20.1664 47 20.1664 47C20.1664 47 40.3335 39.9018 40.3335 11.3571V2.78857C40.3335 7.55449 38.2715 11.3571 31.8342 11.4585Z'
          fill='#055532'
        />
        <path
          d='M51.5488 27.9363H55.7231C57.9862 27.9363 59.5956 28.9503 59.5956 30.9276C59.5956 31.891 59.1429 32.8543 58.288 33.3613V33.412C59.5955 33.8176 60.0482 34.9837 60.0482 35.9978C60.0482 38.4821 57.9862 39.3947 55.7231 39.3947H51.5488V27.9363ZM55.7734 32.3473C56.4774 32.3473 56.7792 31.8403 56.7792 31.2826C56.7792 30.7755 56.4774 30.3192 55.7231 30.3192H54.3652V32.3473H55.7734ZM55.9745 36.9611C56.7792 36.9611 57.1815 36.4034 57.1815 35.7443C57.1815 35.0851 56.7792 34.5781 55.9745 34.5781H54.3149V36.9611H55.9745Z'
          fill='currentColor'
        />
        <path
          d='M64.323 27.9363H67.2399L71.1124 39.344H68.2458L67.5417 36.9611H64.0212L63.3172 39.344H60.4505L64.323 27.9363ZM66.8879 34.7302L66.2341 32.4994C66.0329 31.7896 65.7815 30.522 65.7815 30.522H65.7312C65.7312 30.522 65.4797 31.7896 65.2786 32.4994L64.6248 34.7302H66.8879Z'
          fill='currentColor'
        />
        <path
          d='M72.2188 27.9363H75.0352L78.1533 33.2599C78.6059 34.0204 79.1089 35.1865 79.1089 35.1865H79.1591C79.1591 35.1865 79.0083 33.9697 79.0083 33.2599V27.9363H81.7743V39.344H79.0083L75.8399 34.0204C75.3872 33.2599 74.8843 32.0938 74.8843 32.0938H74.834C74.834 32.0938 74.9849 33.3106 74.9849 34.0204V39.344H72.2188V27.9363Z'
          fill='currentColor'
        />
        <path
          d='M86.9544 27.9363V32.3473H88.1111L90.4748 27.9363H93.4421L90.3743 33.412V33.4627L93.5929 39.3947H90.4748L88.0608 34.7809H86.9041V39.3947H84.1381V27.9363H86.9544Z'
          fill='currentColor'
        />
        <path
          d='M51.5488 22.765V6.74344H61.3055V7.90957C61.3055 9.1264 60.3499 10.0897 59.1429 10.0897H55.4213V13.436H60.2493V14.5514C60.2493 15.7683 59.2435 16.7823 58.0365 16.7823H55.4213V22.7143H51.5488V22.765Z'
          fill='currentColor'
        />
        <path
          d='M67.3905 13.9937H69.1004C69.7542 13.9937 70.2571 13.8416 70.6092 13.4867C70.9612 13.1318 71.1624 12.6755 71.1624 12.0164C71.1624 11.1544 70.8606 10.546 70.2068 10.2925C69.8548 10.1404 69.3519 10.0897 68.7484 10.0897H67.4408V13.9937H67.3905ZM63.4677 6.74344H68.9998C70.408 6.74344 71.4138 6.89555 72.0676 7.14905C72.9729 7.50396 73.7273 8.06167 74.2302 8.87289C74.7331 9.68411 74.9846 10.6474 74.9846 11.8136C74.9846 12.7262 74.7834 13.5881 74.3811 14.3993C73.9787 15.2105 73.3752 15.819 72.6208 16.2246V16.2753C72.7717 16.4781 72.9729 16.7823 73.2243 17.2386L76.2922 22.8664H72.0173L69.201 17.4414H67.3905V22.8664H63.4677V6.74344Z'
          fill='currentColor'
        />
        <path
          d='M78.1027 22.765V6.74344H88.1108V10.0897H82.0254V13.0304H86.8535V16.3767H82.0254V19.4187H88.4125V22.765H78.1027Z'
          fill='currentColor'
        />
        <path
          d='M90.8771 22.765V6.74344H100.835V10.0897H94.7999V13.0304H99.6279V16.3767H94.7999V19.4187H101.187V22.765H90.8771Z'
          fill='currentColor'
        />
        <path
          d='M107.524 19.4187H109.133C110.491 19.4187 111.547 19.0131 112.301 18.2019C113.056 17.3907 113.458 16.2246 113.458 14.7035C113.458 13.2332 113.056 12.0671 112.301 11.2558C111.547 10.4446 110.491 10.0897 109.133 10.0897H107.524V19.4187ZM103.651 22.765V6.74344H109.284C111.798 6.74344 113.81 7.45326 115.269 8.87289C116.727 10.2925 117.481 12.2192 117.481 14.7542C117.481 17.2893 116.727 19.2159 115.269 20.6356C113.81 22.0552 111.798 22.765 109.284 22.765H103.651Z'
          fill='currentColor'
        />
        <path
          d='M123.215 14.6531C123.215 16.0221 123.617 17.1882 124.422 18.1008C125.226 19.0134 126.283 19.4697 127.49 19.4697C128.747 19.4697 129.753 19.0134 130.557 18.1008C131.362 17.1882 131.764 16.0221 131.764 14.6531C131.764 13.3349 131.362 12.2195 130.557 11.3575C129.753 10.4956 128.747 10.0393 127.49 10.0393C126.232 10.0393 125.226 10.4956 124.422 11.3575C123.617 12.2195 123.215 13.3349 123.215 14.6531ZM119.191 14.6531C119.191 12.3209 119.946 10.3942 121.505 8.82249C123.064 7.25075 125.076 6.49023 127.49 6.49023C129.904 6.49023 131.915 7.25075 133.474 8.82249C135.033 10.3942 135.788 12.3209 135.788 14.6531C135.788 17.0361 135.033 19.0641 133.474 20.6359C131.915 22.2583 129.904 23.0188 127.49 23.0188C125.076 23.0188 123.064 22.2076 121.505 20.6359C119.946 19.0134 119.191 17.0361 119.191 14.6531Z'
          fill='currentColor'
        />
        <path
          d='M137.648 22.765L138.956 6.74344H143.181L145.494 13.5374L146.299 16.1739H146.349C146.651 15.1091 146.902 14.1965 147.154 13.5374L149.467 6.74344H153.692L154.999 22.765H151.127L150.624 15.5654C150.573 15.2105 150.573 14.8049 150.573 14.3486C150.573 13.8923 150.573 13.5374 150.573 13.2839V12.8783H150.523C150.171 13.9937 149.869 14.9063 149.618 15.5654L147.958 20.23H144.639L142.979 15.5654L142.074 12.8783H142.024C142.074 13.8416 142.074 14.7542 142.024 15.5654L141.521 22.765H137.648Z'
          fill='currentColor'
        />
      </svg>
    </div>
  );
}

export default BigLogo;
