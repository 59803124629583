import * as React from 'react';

function XIcon(props) {
  return (
    <svg width={44} height={44} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.906 22.073L1.784 35.195a3.716 3.716 0 00.003 5.26L3.54 42.21a3.719 3.719 0 005.26 0l13.122-13.121 13.185 13.184a3.719 3.719 0 005.26 0l1.754-1.753a3.719 3.719 0 000-5.261L28.936 22.073 42.121 8.89a3.719 3.719 0 000-5.26l-1.757-1.754a3.718 3.718 0 00-5.26 0L21.921 15.058 8.801 1.938a3.72 3.72 0 00-5.263.003L1.785 3.694a3.717 3.717 0 000 5.26l13.121 13.12z'
        fill='#FB5555'
      />
    </svg>
  );
}

export default XIcon;
