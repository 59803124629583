/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'twin.macro';

import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useClickAway } from 'react-use';
import tw from 'twin.macro';

import BlueCard from '@/assets/images/prepaid_blue.webp';
import GrayCard from '@/assets/images/prepaid_gray.webp';
import GreenCard from '@/assets/images/prepaid_green.webp';
import MintCard from '@/assets/images/prepaid_mint.webp';
import PinkCard from '@/assets/images/prepaid_pink.webp';
import { ThemeContext } from '@/contexts/theme-context';
import { convertPan } from '@/helper';

import ContainerSkeleton from '../ui/container-skeleton/container-skeleton';
import { Title } from '../ui/title/title';

const months_kz = [
  'қаңтар',
  'ақпан',
  'наурыз',
  'сәуір',
  'мамыр',
  'маусым',
  'шілде',
  'тамыз',
  'қыркүйек',
  'қазан',
  'қараша',
  'желтоқсан'
];

const convertExpDate = date => {
  const month = date.split('.')[0];
  const year = date.split('.')[1].slice(2, 4);
  return month.concat('/', year);
};
const buttonStyle = [tw`border border-green-alpha text-s16 py-3 px-5 rounded-xl w-full text-green`];
function PrepaidCardContainer({ handleClickOrdering, handleSendSms = Function.prototype, card, idx, onLoad }) {
  const [loaded, setLoaded] = useState(false);
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];

  useClickAway(ref, () => {
    setOpen(false);
  });

  const returnCardImage = useCallback(product_code => {
    switch (product_code) {
      case 'PREPAID_50':
        return <img src={PinkCard} alt='розовая-карта' onLoad={() => setLoaded(true)} />;
      case 'PREPAID_100':
        return <img src={GreenCard} alt='зеленая-карта' onLoad={() => setLoaded(true)} />;
      case 'PREPAID_200':
        return <img src={BlueCard} alt='синяя-карта' onLoad={() => setLoaded(true)} />;
      case 'PREPAID_300':
        return <img src={MintCard} alt='синяя-карта' onLoad={() => setLoaded(true)} />;
      case 'PREPAID_400':
        return <img src={GrayCard} alt='синяя-карта' onLoad={() => setLoaded(true)} />;
      default:
        break;
    }
  }, []);

  const convertDate = useCallback(
    date => {
      if (lang === 'ru') {
        const formattedDate = new Date(date).toLocaleString('ru-RU', {
          day: 'numeric',
          month: 'long',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
          hourCycle: 'h23'
        });
        return formattedDate.replace('в', '');
      } else {
        const options = {
          day: 'numeric',
          month: 'long',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
          hourCycle: 'h23'
        };
        const dateObj = new Date(date);
        const day = dateObj.getDate();
        const month = months_kz[dateObj.getMonth()];
        const time = dateObj.toLocaleTimeString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hourCycle: 'h23'
        });
        console.log('time kk', time);
        return `${day} ${month} ${time}`;
      }
    },
    [lang]
  );
  return (
    <AnimatePresence>
      <motion.div
        tw='bg-secondary rounded-2xl p-5 flex flex-col gap-5 cursor-pointer sm:mb-0 mb-5 min-h-[324px] max-h-[600px] justify-between'
        key={idx}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        onLoad={onLoad}
        transition={{ duration: 0.5 }}
      >
        <div tw='flex w-full justify-between relative'>
          <Title text={`Карта №${card?.number}`} variant={'bold'} />
          <span
            tw='bg-green px-3 rounded-2xl flex items-center font-bold text-primary-inverted'
            onClick={() => setOpen(!open)}
            onMouseOver={() => setOpen(true)}
            onMouseOut={() => setOpen(false)}
            ref={ref}
          >{`${card.balance ?? '0'} $`}</span>

          <AnimatePresence>
            <motion.span
              tw='absolute text-center w-full z-50'
              initial={{ opacity: 0 }}
              animate={open ? { opacity: 1 } : { opacity: 0 }}
            >
              <span tw='absolute top-9 right-3'>
                <svg width={16} height={8} fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M7.293.707a1 1 0 011.414 0L16 8H0L7.293.707z'
                    fill={theme === 'light' ? '#DDF6CE' : '#454545'}
                  />
                </svg>
              </span>
              <span tw='bg-calc-footer px-8 py-5 rounded-md absolute sm:left-5 -right-2 top-11 z-50 min-w-[300px]'>
                {`${t('my_cards.card.rest')} ${convertDate(card?.date_on_balance)}`}
              </span>
            </motion.span>
          </AnimatePresence>
        </div>
        <div tw='relative'>
          {returnCardImage(card?.product_code)}
          {loaded ? (
            <>
              <span tw='absolute  bottom-[15%] left-3 sm:text-s18 2xl:text-s16 xl:text-s16 lg:text-s16 md:text-s18 text-s18 '>
                {convertPan(card?.number_card)}
              </span>
              <span tw='absolute bottom-[5%] left-3 sm:text-s16 2xl:text-s14 xl:text-s14 lg:text-s14 md:text-s16 text-s16'>
                {convertExpDate(card?.expiry_date)}
              </span>
            </>
          ) : (
            <ContainerSkeleton twStyle={tw`max-h-[272px] min-h-[168px] min-w-[266px] max-w-[660px] h-full w-full`} />
          )}
        </div>
        <div tw='flex justify-between gap-5'>
          <button css={[buttonStyle[0]]} onClick={() => handleSendSms(card)}>
            {t('my_cards.card.button.sms')}
          </button>
          <button css={[buttonStyle[0]]} tw='w-[40%]' onClick={() => handleClickOrdering(card)}>
            {t('my_cards.card.button.statement')}
          </button>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default PrepaidCardContainer;
