/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import tw from 'twin.macro';

import { useGetPrepaidGeneralInfoQuery } from '@/app/api/prepaid-general-info';
import { answersPrepaidFunctionKz, answersPrepaidFunctionRu } from '@/app/utils/constants';
import SectionBlock from '@/components/ui/section-block/section-block';
import { DeviceInfoContext } from '@/contexts/device-info-context';

import RevealingQAContainer from '../revealing-qa-container';

function LandingPrepaidQA() {
  const [activeTab, setActiveTab] = useState(0);
  const [answers, setAnswers] = useState([]);
  const { isMobile } = useContext(DeviceInfoContext);
  const { t, i18n } = useTranslation();
  const { data } = useGetPrepaidGeneralInfoQuery();
  const tabs = [
    t('landing.qa.tabs.open'),
    t('landing.qa.tabs.use'),
    t('landing.qa.tabs.legal'),
    t('landing.qa.tabs.problem')
  ];

  useEffect(() => {
    if (i18n.language.includes('kk')) {
      setAnswers(answersPrepaidFunctionKz(data?.data?.open_card_limit, activeTab + 1));
    } else {
      setAnswers(answersPrepaidFunctionRu(data?.data?.open_card_limit, activeTab + 1));
    }
  }, [i18n.language, t, activeTab, data]);

  console.log('navigator', navigator.userAgent);
  return (
    <SectionBlock title={t('landing.qa.title')}>
      {isMobile ? (
        <div tw='flex rounded-2xl overflow-hidden  flex-col gap-1'>
          {tabs?.map((item, i) => (
            <>
              <p
                key={item.question}
                tw='bg-secondary px-6 py-5 text-[18px] cursor-pointer'
                css={activeTab === i && tw`bg-accent text-primary-inverted`}
                onClick={() => setActiveTab(i)}
              >
                {item}
              </p>
              {activeTab === i && (
                <div tw='flex flex-col divide-y divide-grey w-full border-grey'>
                  {data && answers?.map(item => <RevealingQAContainer qa={item} key={item.question} />)}
                </div>
              )}
            </>
          ))}
        </div>
      ) : (
        <div tw='flex items-start  gap-8'>
          <div tw='flex rounded-2xl w-[40%] overflow-hidden  flex-col gap-1'>
            {tabs?.map((item, i) => (
              <p
                key={item.question}
                tw='bg-secondary px-6 py-5 text-[18px] cursor-pointer'
                css={activeTab === i && tw`bg-accent text-primary-inverted`}
                onClick={() => setActiveTab(i)}
              >
                {item}
              </p>
            ))}
          </div>
          <div tw='flex flex-col divide-y divide-grey w-[60%] border-grey'>
            {data ? answers?.map(item => <RevealingQAContainer qa={item} key={item.question} />) : <ClipLoader />}
          </div>
        </div>
      )}
    </SectionBlock>
  );
}

export default LandingPrepaidQA;
