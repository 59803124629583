import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { PURGE } from 'redux-persist';

import { setError } from '@/app/store/slices/error';

import { setAuth } from '../store/slices/auth';
import { setRequestTimestamp } from '../store/slices/requestTimer';
import { APIUrl, isWorld } from './API-Url';

const baseQuery = fetchBaseQuery({
  baseUrl: '/',
  credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.access;
    if (token) headers.set('Authorization', `JWT ${token}`);
    headers.set('Accept', 'application/json');
    return headers;
  }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  const lastRequestDate = new Date(api.getState().requestTimestamp.lastRequestTime);
  const refresh = api.getState().auth.refresh;
  //15 * 60 * 1000 = 900000 15 minutes in milliseconds
  console.log('lastRequestDate', Math.abs(new Date() - lastRequestDate));
  const timeDifferenceMoreThan15 = Math.abs(new Date() - lastRequestDate) > 15 * 60 * 1000;

  console.log('timeDifferenceMoreThan15', timeDifferenceMoreThan15);
  if (result.error?.status === 'FETCH_ERROR' || result.error?.originalStatus === 500) {
    const pattern = /\/rates\//;
    if (!pattern.test(result.error?.data)) {
      api.dispatch(
        setError(
          'Просим прощения за неудобства, но в данный момент по техническим причинам сервис недоступен. Пожалуйста, попробуйте позднее'
        )
      );
      //rates - стреляет 500 , для обхода ошибки использую условие
    }
  }

  if (result.error && result.error.status === 401) {
    if (!timeDifferenceMoreThan15 && refresh) {
      // const result = await api.dispatch(refreshApi.endpoints.refresh.initiate(null, { forceRefetch: true }));
      try {
        const refreshResult = await fetch(`${APIUrl}/auth/refresh/`, {
          method: 'POST',
          body: JSON.stringify({ refresh }),
          headers: {
            'Content-Type': 'application/json;charset=UTF-8'
          }
        });
        if (refreshResult.status === 200) {
          // store the new token
          const accessResponse = await refreshResult.json();
          api.dispatch(setAuth(accessResponse.data));
          // retry the initial query
          result = await baseQuery(args, api, extraOptions);
        }
      } catch (error) {
        await api.dispatch({ type: PURGE, result: () => null });
      }
    } else {
      const apiUrl = isWorld ? import.meta.env.NR_HOMEPAGE_WORLD : import.meta.env.NR_HOMEPAGE;
      console.log('The interval is greater than 15 minutes.');
      await api.dispatch({ type: PURGE, result: () => null });
      result = await baseQuery(args, api, extraOptions);
      window.open(apiUrl, '_self');
    }

    // await api.dispatch({ type: PURGE, result: () => null });
    // result = await baseQuery(args, api, extraOptions);
  }
  if (result.error && result.error.status === 401) {
    await api.dispatch({ type: PURGE, result: () => null });
    result = await baseQuery(args, api, extraOptions);
  }
  if (result.data?.data) {
    api.dispatch(setRequestTimestamp(new Date()));
  }
  return result;
};

//http://192.168.1.194:8000
export const api = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  mode: 'no-cors',
  tagTypes: [],
  endpoints: () => ({})
});
