import React from 'react';
import tw from 'twin.macro';

const styles = {
  container: ({ twStyles }) => [
    tw`bg-secondary sm:rounded-s20 sm:shadow-content mt-[3px] sm:mt-[40px] p-[12px] shadow-sm`,
    twStyles
  ]
};

const StepContentWrapper = ({ children, twStyles }) => {
  return <div css={styles.container({ twStyles })}>{children}</div>;
};

export { StepContentWrapper };
