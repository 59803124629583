import * as React from 'react';

function PhoneIcon(props) {
  return (
    <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M2.436 2.577l2.437-.563a.566.566 0 01.645.326l1.125 2.625c.098.23.033.5-.162.656l-1.42 1.163a8.686 8.686 0 004.153 4.153l1.162-1.42a.562.562 0 01.657-.162l2.625 1.125a.57.57 0 01.328.647l-.563 2.437a.562.562 0 01-.548.436C6.873 14 2 9.137 2 3.125c0-.262.18-.49.436-.548z'
        fill='var(--text-secondary)'
      />
    </svg>
  );
}

export default PhoneIcon;
