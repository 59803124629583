import { useContext } from 'react';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { ThemeContext } from '@/contexts/theme-context';

function ApplePayIcon(props) {
  const location = useLocation().pathname;
  const { theme } = useContext(ThemeContext);
  const isDark = theme === 'dark' && location.includes('success');
  return (
    <svg width={49} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8.875 2.579c-.57.675-1.48 1.208-2.391 1.132-.114-.914.332-1.884.854-2.484C7.908.533 8.903.038 9.71 0c.095.951-.275 1.884-.835 2.579zM9.7 3.891c-1.318-.077-2.447.751-3.074.751-.635 0-1.594-.713-2.637-.694-1.357.019-2.619.79-3.311 2.017-1.424 2.455-.37 6.09 1.005 8.087.674.99 1.48 2.075 2.543 2.036 1.006-.038 1.404-.656 2.619-.656 1.223 0 1.575.656 2.637.637 1.1-.019 1.793-.99 2.467-1.979.768-1.122 1.082-2.217 1.1-2.274-.018-.019-2.125-.828-2.144-3.263-.019-2.036 1.66-3.007 1.737-3.064-.95-1.408-2.43-1.56-2.942-1.598zm7.619-2.76v14.834h2.296v-5.072h3.178c2.904 0 4.944-1.998 4.944-4.89 0-2.893-2.002-4.872-4.868-4.872h-5.55zm2.296 1.941h2.647c1.992 0 3.131 1.066 3.131 2.94 0 1.875-1.139 2.95-3.14 2.95h-2.638v-5.89zM31.93 16.08c1.442 0 2.78-.733 3.387-1.893h.048v1.779h2.125V8.582c0-2.14-1.708-3.52-4.336-3.52-2.438 0-4.241 1.399-4.308 3.32h2.069c.17-.913 1.015-1.512 2.172-1.512 1.405 0 2.192.656 2.192 1.865v.818l-2.865.171c-2.666.162-4.108 1.256-4.108 3.159 0 1.922 1.49 3.197 3.624 3.197zm.617-1.76c-1.224 0-2.002-.59-2.002-1.494 0-.933.75-1.475 2.182-1.56l2.552-.162v.837c0 1.39-1.176 2.379-2.732 2.379zm7.78 5.68c2.239 0 3.292-.856 4.212-3.454l4.032-11.341h-2.334l-2.704 8.763h-.047l-2.704-8.763h-2.4l3.89 10.799-.21.656c-.35 1.114-.92 1.542-1.935 1.542a7.18 7.18 0 01-.673-.038v1.779c.133.038.702.057.873.057z'
        fill={isDark ? '#fff' : '#000'}
      />
    </svg>
  );
}

export default ApplePayIcon;
