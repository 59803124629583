import * as React from 'react';

function PdfIcon(props) {
  return (
    <svg width={32} height={32} fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.333 2.667H12c-6.667 0-8 2.97-8 8.908v8.894c0 6.775 2.227 8.591 6.667 8.865H18m7.333-16v-2.667'
        stroke={props.color ? props.color : '#72bf44'}
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap='round'
        fill='none'
        strokeLinejoin='round'
      />
      <path
        d='M25.334 10.667l-8-8v4c0 2.667 1.333 4 4 4h4z'
        stroke={props.color ? props.color : '#72bf44'}
        strokeWidth={1.5}
        strokeLinecap='round'
        fill='none'
        strokeLinejoin='round'
      />
      <path
        d='M11.99 17.334c1.023 0 1.777.224 2.263.672.493.441.74 1.057.74 1.849 0 .358-.053.702-.158 1.03a2.21 2.21 0 01-.526.864c-.24.254-.557.455-.953.605-.388.149-.87.224-1.445.224h-.672v2.756H9.334v-8h2.655zm-.068 1.557h-.683v2.13h.504a1.9 1.9 0 00.683-.113.952.952 0 00.471-.358c.112-.165.168-.378.168-.639 0-.329-.093-.579-.28-.75-.187-.18-.474-.27-.863-.27zM23.092 21.21c0 .912-.172 1.674-.516 2.286a3.265 3.265 0 01-1.479 1.379c-.642.306-1.408.459-2.297.459h-2.409v-8h2.634c.844 0 1.568.15 2.173.448.613.299 1.08.736 1.4 1.311.33.568.494 1.274.494 2.118zm-1.972.068c0-.545-.079-.993-.236-1.345a1.572 1.572 0 00-.683-.784c-.299-.172-.672-.258-1.12-.258h-.785v4.874h.617c.754 0 1.31-.205 1.669-.616.358-.418.538-1.042.538-1.871zM26.535 25.334h-1.882v-8h4.65v1.546h-2.768v1.815h2.566v1.546h-2.566v3.093z'
        fill={props.color ? props.color : '#72bf44'}
      />
    </svg>
  );
}

export default PdfIcon;
