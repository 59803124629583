import { useEffect, useState } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getIsAuth } from '@/app/store/slices/auth';
import AuthModal from '@/views/auth-modal';

export const RequireAuth = ({ children }) => {
  const [open, setOpen] = useState();
  const isAuth = useSelector(getIsAuth);
  const location = useLocation();

  // const navigate = useNavigate();

  useEffect(() => {
    !isAuth && setOpen(true);
  }, [isAuth]);
  if (!isAuth) {
    return (
      <>
        <AuthModal open={open} setOpen={setOpen} showLabel={false} />
      </>
    );
  }

  return children;
};
