import 'twin.macro';

import { Fragment, Suspense } from 'react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';

import { pathnames } from '@/app/utils/constants';
import { RequireAuth } from '@/helper/RequireAuth';
import StepLayout from '@/views/layouts/step-layout';

const ApplyPage = React.lazy(() => import('@/pages/steps/apply-page'));
const VerifyPage = React.lazy(() => import('@/pages/steps/verify-page'));
const CardTypePage = React.lazy(() => import('@/pages/steps/card-type-page'));
const WaitingPage = React.lazy(() => import('@/pages/steps/waiting-page'));
const SuccessPage = React.lazy(() => import('@/pages/steps/success-page'));

function PrepaidSteps() {
  return (
    <Fragment>
      <Suspense
        fallback={
          <div tw='w-full flex justify-center h-[95vh] items-center'>
            <BeatLoader color='rgb(42, 134, 64)' />
          </div>
        }
      >
        <Routes>
          <Route path={pathnames.prepaid_card} element={<StepLayout />}>
            <Route path={pathnames.apply} element={<ApplyPage />} />
            <Route path={pathnames.verify} element={<VerifyPage />} />
            <Route
              path={pathnames.card_type}
              element={
                <RequireAuth>
                  <CardTypePage />
                </RequireAuth>
              }
            />
            <Route
              path={pathnames.waiting}
              element={
                <RequireAuth>
                  <WaitingPage />
                </RequireAuth>
              }
            />
            <Route path={pathnames.success} element={<SuccessPage />} />
          </Route>
        </Routes>
      </Suspense>
    </Fragment>
  );
}

export default PrepaidSteps;
