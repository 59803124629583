export const prepaid = {
  banner: {
    title: 'Предоплаченные карты',
    subtitle: {
      benefit1: 'Оформление онлайн за 5 минут без посредников',
      benefit2: 'Моментальный выпуск цифровой карты',
      benefit3: 'Номиналы в 50, 100, 200, 300 и 400 долларов'
    },
    button: 'Купить карту',
    disabled: 'Покупка карт временно недоступна. Ведутся технические работы.'
  },
  hiw: {
    title: 'Как работает карта',
    subtitle1:
      'Цифровая предоплаченная карта открывается без документов и счёта. Ей можно оплатить покупки в магазине или подписку в интернете ',
    subtitle2: ' Когда закончатся деньги — карта закроется автоматически',
    benefits: {
      // benefit1: 'Оплаты по всему миру и в интернете, переводы на другие карты',
      benefit1: 'Оплаты по всему миру и в интернете',
      benefit2: 'Есть CVC и 3D-secure, работает в электронных кошельках',
      benefit3: 'Для открытия не нужны документы, достаточно номера телефона',
      benefit4: 'Пополнение, снятие денег или переводы с карты на карту невозможны'
    }
  },
  conditions: {
    title: 'Условия и тарифы',
    line1: {
      column: 'Вид карты',
      value: 'Mastercard Prepaid',
      caption: 'цифровая'
    },
    line2: {
      column: 'Выпуск',
      value: {
        first: {
          title: '50 $ на карту',
          caption: '+ 3 000 ₸'
        },
        second: {
          title: '100 $ на карту',
          caption: '+ 5 000 ₸'
        },
        third: {
          title: '200 $ на карту',
          caption: '+ 7 000 ₸'
        },
        fourth: {
          title: '300 $ на карту',
          caption: '+ 9 000 ₸'
        },
        fifth: {
          title: '400 $ на карту',
          caption: '+ 11 000 ₸'
        }
      }
    },
    line3: {
      column: 'Валюта',
      value: 'доллары'
    },
    line4: {
      column: 'Срок действия',
      value: '6 месяцев с момента выпуска ',
      caption: 'Карта закроется автоматически, когда закончится срок действия или деньги на карте'
    },
    line5: { column: 'Оплаты в торговых точках и интернете', value: 'без комиссии' },
    line6: { column: 'Переводы на карты других банков', value: '1%', caption: 'минимум 250 тенге' },
    line7: { column: 'Пополнения', value: 'нет' },
    line8: { column: 'Снятие наличных', value: 'нет' }
  },
  qa: {
    title: 'Часто задаваемые вопросы',
    tabs: {
      open: 'Открытие и получение',
      use: 'Использование',
      legal: 'Налоговые и юридические вопросы',
      problem: 'Решение проблем'
    }
  }
};

export const my_documents_prepaid = {
  selector: {
    active: 'Активные',
    inactive: 'Закрытые'
  },
  card: {
    title: 'Карта',
    card_number: 'Номер карты и CVV',
    ordering: 'Выписка'
  }
};
