import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { getApplyData, getCardType } from '@/app/store/slices/app';
import { clearCustomError, getCustomErrors } from '@/app/store/slices/error';
import BigExclamationIcon from '@/assets/images/icons/BigExclamationIcon';
import XIcon from '@/assets/images/icons/XIcon';
import { BodyText, Button, StepContentWrapper } from '@/components';
import useCheckStatusPrepaid from '@/helper/hooks/useCheckStatusPrepaid';

const ErrorPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customError = useSelector(getCustomErrors);
  const card_type = useSelector(getCardType);
  const applyData = useSelector(getApplyData);
  const navigate = useNavigate();

  const [checkStatusPrepaid] = useCheckStatusPrepaid();
  const handleBackHome = () => {
    navigate('/');
    dispatch(clearCustomError());
  };

  const handleGoResidentSite = () => {
    window.open('https://bankffin.kz/ru/card-issuance/deposit-card', '_blank');
  };

  const handleRetry = () => {
    dispatch(clearCustomError());
  };
  const [authOpen, setAuthOpen] = useState(false);

  const renderButton = customError => {
    switch (String(customError?.code).toLowerCase()) {
      case 'pp_rejected':
        return (
          <Button
            variant='secondary'
            onClick={() => {
              navigate('/');
              dispatch(clearCustomError());
              //  window.open(`${import.meta.env.NR_HOMEPAGE}/prepaid-card/apply`, '_self');
            }}
          >
            {t('steps.buttons.card_page')}
          </Button>
        );
      case 'pp_canceled':
        return (
          <Button
            variant='secondary'
            onClick={() => {
              navigate('/');
              dispatch(clearCustomError());
              //  window.open(`${import.meta.env.NR_HOMEPAGE}/prepaid-card/apply`, '_self');
            }}
          >
            {t('steps.buttons.card_page')}
          </Button>
        );
      case 'error':
        if (card_type === 'prepaid-card') {
          return (
            <Button
              variant='secondary'
              onClick={() => {
                navigate('/apply');
                dispatch(clearCustomError());
              }}
            >
              {t('steps.buttons.try')}
            </Button>
          );
        } else {
          break;
        }
      default:
        return (
          <Button variant='secondary' onClick={handleBackHome}>
            {t('steps.buttons.main_page')}
          </Button>
        );
    }
  };

  return (
    <div>
      <StepContentWrapper twStyles={tw`text-center max-w-content mx-auto py-20`}>
        <div tw='max-w-[416px] mx-auto flex flex-col gap-5 mb-[45px]'>
          {card_type !== 'prepaid-card' ? (
            <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-yellow-alpha20 mt-[48px]'>
              <BigExclamationIcon />
            </div>
          ) : (
            <div tw='w-[144px] h-[144px] flex items-center justify-center rounded-full mx-auto bg-red-alpha20 mt-[48px]'>
              <XIcon />
            </div>
          )}
          <BodyText
            text={
              customError?.description
                ? customError?.code === 'pp_rejected' || customError.code === 'pp_canceled'
                  ? customError.code === 'pp_rejected'
                    ? t('steps.err_desc.rejected')
                    : t('steps.err_desc.canceled')
                  : customError?.description
                : t('steps.err_desc.technical')
            }
            twStyle={tw`text-secondary text-center`}
          />
          {renderButton(customError)}
        </div>
      </StepContentWrapper>
    </div>
  );
};

export default ErrorPage;
