import 'swiper/css';

import { propIs } from 'ramda';
import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';

import { BodyText } from '../body-text/body-text';
import { Caption } from '../caption/caption';

/*SelectorProps {
  items: {
    value: string;
    title: string;
    subItem?: string | JSX.Element;
  }[];
  small?: boolean;
  getActiveItem: ({ activeItem: string }) => void;
  defaultActiveItem?: string;
}*/

const styles = {
  container: ({ small, withIcon, isPlate, disabled, twStyle }) => [
    tw`flex justify-center w-full`,
    small && tw`w-auto`,
    withIcon && tw`flex-row space-x-0 space-x-2`,
    isPlate && tw`space-x-0 flex-col space-y-2 sm:space-y-0 sm:space-x-2 sm:flex-row`,
    disabled && tw`opacity-50`,
    twStyle
  ],
  button: ({ isActive, small, type, isPlate, disabled, idx, items, buttonStyle }) => {
    return [
      tw` flex justify-between items-center flex-wrap border border-green-alpha w-full py-3 px-1 hover:border-button leading-s18 text-green`,
      isActive && tw` border-green hover:border-green text-primary-inverted bg-green`,
      small && tw` px-3 py-2`,
      isPlate && tw`h-[34px] py-0`,
      disabled && tw`border-0 cursor-not-allowed`,
      disabled && isActive && tw`border border-green`,
      idx === 0 && tw`rounded-l-2xl`,
      idx === 0 && idx === items.length - 1 && tw``,
      idx === items.length - 1 && tw`rounded-r-2xl`,
      buttonStyle
    ];
  },
  textContainer: ({ icon }) => [
    tw`flex flex-col justify-center space-y-2 items-center flex-1 `,
    icon && tw`items-start`
  ]
};

const Selector2 = ({
  items,
  small,
  getActiveItem = () => {},
  defaultActiveItem,
  withIcon,
  isPlate = false,
  twStyle,
  type = 'primary',
  variant = 'primary',
  disabled,
  textStyle,
  buttonStyle
}) => {
  const [activeItem, setActiveItem] = useState(defaultActiveItem);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    setActiveItem(defaultActiveItem);
  }, [defaultActiveItem]);

  const handleClick = (e, activeItem, disabled) => {
    e.preventDefault();
    if (!disabled) {
      setActiveItem(activeItem);
      getActiveItem(activeItem);
    }
  };

  return (
    <>
      {variant === 'primary' && (
        <div css={styles.container({ small, withIcon, isPlate, disabled, twStyle })}>
          {items.map((item, idx) => {
            const isActive = activeItem === item.value;
            const subItem = propIs(String, 'subItem', item) ? (
              <Caption text={item.subItem} twStyle={isActive ? tw`text-green` : tw`text-secondary`} />
            ) : (
              item.subItem
            );
            return (
              <button
                css={styles.button({ isActive, small, type, isPlate, disabled, idx, items, buttonStyle })}
                key={item.value}
                onClick={e => handleClick(e, item.value, disabled)}
              >
                <div css={styles.textContainer({ icon: item.icon })}>
                  {small ? (
                    <Caption text={item.title} twStyle={isActive ? tw`text-link` : tw`text-primary`} />
                  ) : (
                    <BodyText text={item.title} twStyle={(isActive ? tw`text-secondary` : tw`text-green`, textStyle)} />
                  )}
                  {subItem}
                </div>
                <div>{item.icon}</div>
              </button>
            );
          })}
        </div>
      )}
    </>
  );
};

export { Selector2 };
