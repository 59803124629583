export const prepaid_kz = {
  banner: {
    title: 'Алдын ала төленген карталар',
    subtitle: {
      benefit1: 'Онлайн рәсімдеу 5 минут ішінде делдалдарсыз',
      benefit2: 'Цифрлық картаны лезде шығару',
      benefit3: 'Атаулы құны 50, 100, 200, 300 және 400 доллар'
    },
    button: 'Картаны сатып алу',
    disabled: 'Карталарды сатып алу уақытша қол жетімді емес. Техникалық жұмыстар жүргізілуде.'
  },
  hiw: {
    title: 'Карта қалай жұмыс істейді',
    subtitle1:
      'Цифрлық алдын ала төленген карта құжаттарсыз және шотсыз ашылады. Онымен дүкеннен сатып алулар немесе онлайн жазылымдар үшін төлей аласыз',
    subtitle2: 'Ақша таусылғанда — карта автоматты түрде жабылады',
    benefits: {
      // benefit1: 'Бүкіл әлем бойынша және интернетте төлемдер, басқа карталарға аударымдар',
      benefit1: 'Бүкіл әлем бойынша және интернетте төлемдер',
      benefit2: 'CVC және 3D-secure бар, электрондық әмияндарда жұмыс істейді',
      benefit3: 'Ашу үшін құжаттар қажет емес, телефон нөмірі жеткілікті',
      benefit4: 'Картаға ақша салу, шешіп алу немесе картадан картаға аударымдар мүмкін емес'
    }
  },
  conditions: {
    title: 'Шарттар мен тарифтер',
    line1: {
      column: 'Карта түрі',
      value: 'Mastercard Prepaid',
      caption: 'цифрлық'
    },
    line2: {
      column: 'Шығару',
      value: {
        first: {
          title: 'картаға 50 $',
          caption: '+ 3 000 ₸'
        },
        second: {
          title: 'картаға 100 $',
          caption: '+ 5 000 ₸'
        },
        third: {
          title: 'картаға 200 $',
          caption: '+ 7 000 ₸'
        },
        fourth: {
          title: 'картаға 300 $',
          caption: '+ 9 000 ₸'
        },
        fifth: {
          title: 'картаға 400 $',
          caption: '+ 11 000 ₸'
        }
      }
    },
    line3: {
      column: 'Валюта',
      value: 'доллар'
    },
    line4: {
      column: 'Жарамдылық мерзімі',
      value: 'шығарылған сәттен бастап 6 ай',
      caption: 'Жарамдылық мерзімі аяқталғанда немесе ақша таусылғанда карта автоматты түрде жабылады'
    },
    line5: { column: 'Сауда нүктелеріндегі және интернеттегі төлемдер', value: 'комиссия жоқ' },
    line6: { column: 'Басқа банктердің карталарына аударымдар', value: '1%', caption: 'кем дегенде 250 теңге' },
    line7: { column: 'Толықтырулар', value: 'жоқ' },
    line8: { column: 'Қолма қол ақша алу', value: 'жоқ' }
  },
  qa: {
    title: 'Жиі қойылатын сұрақтар',
    tabs: {
      open: 'Ашу және алу',
      use: 'Пайдалану',
      legal: 'Салық және заң сұрақтары',
      problem: 'Мәселелерді шешу'
    }
  }
};

export const my_documents_prepaid = {
  selector: {
    active: 'Активные',
    inactive: 'Закрытые'
  },
  card: {
    title: 'Карта',
    card_number: 'Номер карты и CVV',
    ordering: 'Выписка'
  }
};
