import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { prepaidCardApi } from '@/app/api/prepaid';
import { setCardType } from '@/app/store/slices/app';
import { setCustomError } from '@/app/store/slices/error';

const useCheckStatusPrepaid = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState('');
  const [statusData, setStatusData] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setCardType('prepaid-card'));
  }, [statusData]);

  console.log(statusData);
  useEffect(() => {
    if (statusData?.step && statusData?.step !== currentStep) {
      setCurrentStep(statusData?.step);
      switch (statusData?.step) {
        case 'CHOICE_PRODUCT':
          navigate('/card-type');
          break;
        case 'PAYMENT':
          if (statusData?.status === 'IN_PROGRESS') {
            navigate('/waiting');
          }
          if (statusData?.status === 'REJECTED') {
            dispatch(
              setCustomError({
                code: 'pp_rejected',
                description: t('steps.err_desc.rejected')
              })
            );
            navigate('/error');
          }
          if (statusData?.status === 'CANCELED') {
            dispatch(
              setCustomError({
                code: 'pp_canceled',
                description: t('steps.err_desc.canceled')
              })
            );
            navigate('/error');
          }
          break;
        case 'COLVIR':
          if (statusData?.status === 'REJECTED') {
            dispatch(
              setCustomError({
                code: 'pp_rejected',
                description: t('steps.err_desc.rejected')
              })
            );
            navigate('/error');
          } else if (statusData?.status === 'CANCELED') {
            dispatch(
              setCustomError({
                code: 'pp_canceled',
                description: t('steps.err_desc.canceled')
              })
            );
            navigate('/error');
          } else {
            navigate('/waiting');
          }
          break;
        case 'FINISHED':
          if (statusData?.status === 'REJECTED') {
            dispatch(
              setCustomError({
                code: 'pp_rejected',
                description: t('steps.err_desc.rejected')
              })
            );
            navigate('/error');
          } else if (statusData?.status === 'CANCELED') {
            dispatch(
              setCustomError({
                code: 'pp_canceled',
                description: t('steps.err_desc.canceled')
              })
            );
            navigate('/error');
          } else {
            navigate('/waiting');
          }
          break;
        default:
          break;
      }
    }
  }, [navigate, statusData, currentStep]);

  const checkStatusPrepaid = useCallback(() => {
    dispatch(prepaidCardApi.endpoints.prepaidGetStatus.initiate(null, { forceRefetch: true }))
      .unwrap()
      .then(res => {
        setStatusData(res.data);
      })
      .catch(err => {});
  }, []);

  return [checkStatusPrepaid];
};

export default useCheckStatusPrepaid;
