import * as React from 'react';

function DamageIcon(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M42.816 13.339c2.502-2.554 6.893-1.46 7.904 1.97l9.093 30.867c1.01 3.43-2.118 6.622-5.63 5.746L22.57 44.04c-3.513-.876-4.776-5.163-2.273-7.716l22.52-22.985z'
        fill='url(#prefix__paint0_linear_778_13487)'
      />
      <g filter='url(#prefix__filter0_bi_778_13487)'>
        <path
          d='M28.927 25.313c1.81-3.084 6.336-3.084 8.146 0l16.29 27.75C55.173 56.145 52.91 60 49.29 60H16.71c-3.62 0-5.883-3.854-4.073-6.938l16.29-27.75z'
          fill='#72BF44'
          fillOpacity={0.5}
        />
      </g>
      <path
        d='M33.011 46.306A1.996 1.996 0 0035 44.323v-6.365C35 36.875 34.109 36 33.011 36 31.914 36 31 36.875 31 37.958v6.365c0 1.08.914 1.983 2.011 1.983zM31 52.02c0 1.078.914 1.98 2.011 1.98A2 2 0 0035 51.995c0-1.078-.891-1.957-1.989-1.957-1.097 0-2.011.9-2.011 1.982z'
        fill='#fff'
        fillOpacity={0.9}
      />
      <defs>
        <linearGradient
          id='prefix__paint0_linear_778_13487'
          x1={26.951}
          y1={7}
          x2={63.515}
          y2={11.155}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FB84E' />
          <stop offset={1} stopColor='#2A8640' />
        </linearGradient>
        <filter
          id='prefix__filter0_bi_778_13487'
          x={8}
          y={19}
          width={50}
          height={45}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation={2} />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_778_13487' />
          <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_778_13487' result='shape' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={0.2} dy={0.1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2={-1} k3={1} />
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0' />
          <feBlend in2='shape' result='effect2_innerShadow_778_13487' />
        </filter>
      </defs>
    </svg>
  );
}

export default DamageIcon;
