/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import * as yup from 'yup';

import { useMyDocumentsAuthMutation, useMyDocumentsSendOtpMutation } from '@/app/api/my-documents';
import { setAuth } from '@/app/store/slices/auth';
import { getChannel } from '@/app/store/slices/channel';
import ProfileIcon from '@/assets/images/icons/ProfileIcon';
import { BodyText, Modal, Title } from '@/components';
import { DeviceInfoContext } from '@/contexts/device-info-context';
import { ThemeContext } from '@/contexts/theme-context';
import LoginContent from '@/views/login';

function AuthModal({ open, setOpen, showLabel = true, mobile_phone: phone }) {
  // const { theme, setTheme } = useContext(ThemeContext);
  const [countryFormatLength, setCountryFormatLength] = useState(11);
  const channel = useSelector(getChannel);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isExeceptionCountry, setIsExeceptionCountry] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const { isMobile } = useContext(DeviceInfoContext);
  const schema = yup
    .object({
      mobile_phone: yup
        .string()
        .trim()
        .min(16, t('steps.errors.mobile_phone', { number: 11 }))
        .required(t('steps.errors.neccessary'))
    })
    .required();

  const {
    control,
    setValue,
    watch,
    resetField,
    getValues,
    setError,
    reset,
    formState: { isValid, errors }
  } = useForm({ mode: 'onChange', resolver: yupResolver(schema) });

  const mobile_phone = watch('mobile_phone');
  const code = watch('code');
  const [fetchSendOtp, { isSuccess: sendOtpSuccess, error: sendOtpError, isLoading: sendOtpLoading }] =
    useMyDocumentsSendOtpMutation();
  const [fetchApplyAuth, { isSuccess: applyAuthSuccess, error: applyAuthError, data, isLoading: applyAuthLoading }] =
    useMyDocumentsAuthMutation();

  const sendOtp = useCallback(() => {
    let phone = '';
    if (!mobile_phone.includes('+')) {
      phone = '+' + mobile_phone?.replaceAll(' ', '');
    } else {
      phone = mobile_phone.replaceAll(' ', '');
    }

    if (phone?.length >= 12 && !sendOtpSuccess) {
      fetchSendOtp({ mobile_phone: phone });
    }
  }, [mobile_phone, applyAuthSuccess, fetchSendOtp]);

  const handleResendOtp = useCallback(() => {
    const phone = '+' + mobile_phone?.replaceAll(' ', '');
    fetchSendOtp({ mobile_phone: phone });
  }, [mobile_phone, fetchSendOtp]);

  useEffect(() => {
    if (sendOtpError?.data?.error?.code === 'INVALID_OTP') {
      setError('code', { type: 'value', message: sendOtpError?.data?.error?.description });
    }
    if (sendOtpError?.data?.error?.code === 'NO_ACTIVE_ACCOUNT') {
      setError('mobile_phone', { type: 'value', message: sendOtpError?.data?.error?.description });
    }
    if (sendOtpError?.data?.error?.code === 'NO_ACTIVE_CARD') {
      setError('mobile_phone', { type: 'value', message: sendOtpError?.data?.error?.description });
    }
  }, [sendOtpError, setError]);

  useEffect(() => {
    if (code?.length === 4) {
      if (mobile_phone) {
        const withPlus = '+' + mobile_phone;
        document.cookie = `channel=${channel}; max-age=300`;
        fetchApplyAuth({ code, mobile_phone: withPlus });
      }
    }
  }, [code, mobile_phone, fetchApplyAuth]);

  // useEffect(() => {
  //   dispatch({ type: PURGE, result: () => null });
  // }, [dispatch]);

  useEffect(() => {
    if (applyAuthError) {
      setError('code', { type: 'value', message: applyAuthError?.data?.error?.description });
    }
  }, [applyAuthError, setError]);

  useEffect(() => {
    if (applyAuthSuccess) {
      dispatch(setAuth(data?.data));
      setOpen(false);
      reset();
      navigate('/my-documents');
    }
  }, [applyAuthSuccess]);

  const translate = line => {
    return t(`my_documents_auth.${line}`);
  };
  const benefits = useMemo(
    () => [
      translate('functionality.first'),
      translate('functionality.second'),

      {
        text: translate('functionality.third.title'),
        stickerText: translate('functionality.third.text')
      },
      translate('functionality.fourth'),
      translate('functionality.fifth')
    ],
    []
  );

  console.log('phone', phone);
  return (
    <>
      {showLabel && (
        <div
          tw='flex gap-2 items-center min-w-min'
          onClick={() => {
            setOpen(true);
          }}
        >
          <div
            tw='sm:w-[32px] sm:h-[32px] h-[28px] w-[28px] flex items-center justify-center bg-primary rounded-full'
            css={[theme === 'dark' && tw`bg-secondary`]}
          >
            <ProfileIcon />
          </div>
          {!isMobile && (
            <div tw='flex flex-col'>
              <BodyText text={t('landing.header.signIn')} twStyle={tw`text-s18 float-left `} />
            </div>
          )}
        </div>
      )}
      <Modal open={open} setOpen={setOpen} twStyle={tw`rounded-2xl min-w-[25%]`}>
        <Title text={t('auth.title')} variant={'bold'} />
        <LoginContent
          mobilePhone={phone}
          control={control}
          authSuccess={sendOtpSuccess}
          verifySubmit={sendOtp}
          setIsExeceptionCountry={setIsExeceptionCountry}
          recaptchaValue={''}
          mobilePhoneValid={isValid}
          isExeceptionCountry={isExeceptionCountry}
          loading={sendOtpLoading || applyAuthLoading}
          reset={resetField}
          authReset={handleResendOtp}
          sendOtpError={sendOtpError}
          errors={errors}
          setValue={setValue}
          setCountryFormatLength={setCountryFormatLength}
          getValues={getValues}
          twStyle={tw`px-0 bg-transparent mt-0 py-2`}
        />
      </Modal>
    </>
  );
}

export default AuthModal;
