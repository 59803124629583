import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  uuid: null,
  channel: null,
  expired_at_sumsub: null
};

const baseSelector = state => state.channel;

export const getUuid = createSelector([baseSelector], state => state.uuid);
export const getChannel = createSelector([baseSelector], state => state.channel);
export const getExpiredAtSumsub = createSelector([baseSelector], state => state.expired_at_sumsub);

const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setChannel: (state, { payload }) => {
      state.channel = payload;
    },
    setExpiredAtSumsub: (state, { payload }) => {
      state.expired_at_sumsub = payload;
    }
  }
  // extraReducers: builder => {
  //   builder
  //     .addMatcher(applicationApi.endpoints.apply.matchFulfilled, (state, action) => {
  //       const uuid = path(['payload', 'data', 'uuid'], action);
  //       state.uuid = uuid;
  //     })
  //     .addMatcher(applicationApi.endpoints.getStatus.matchFulfilled, (state, action) => {
  //       const uuid = path(['payload', 'data', 'app_uuid'], action);
  //       state.uuid = uuid;
  //     })
  //     .addMatcher(applicationApi.endpoints.verify.matchFulfilled, (state, { payload }) => {
  //       const userData = jwt_decode(payload.data.access);
  //       state.channel = userData.channel;
  //     })
  //     .addMatcher(applicationApi.endpoints.applyAuth.matchFulfilled, (state, { payload }) => {
  //       const userData = jwt_decode(payload.data.access);
  //       state.channel = userData.channel;
  //     })
  //     .addMatcher(shortLinkApi.endpoints.shortLinkDecode.matchFulfilled, (state, { payload }) => {
  //       const userData = jwt_decode(payload.data.access);
  //       state.channel = userData.channel;
  //     })
  //     .addMatcher(shortLinkApi.endpoints.personalShortLinkDecode.matchFulfilled, (state, { payload }) => {
  //       state.channel = payload.data.channel;
  //     });
  // }
});

export const { setChannel, setExpiredAtSumsub } = channelSlice.actions;

export default channelSlice.reducer;
