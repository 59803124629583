import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import tw from 'twin.macro';

import { BodyText, SubBody } from '@/components';
import PrepaidCardContainer from '@/components/prepaid-cards/prepaid-card';

import TablePagination from './table-pagination';

function PrepaidCardslist({
  cardsIsLoading,
  cards,
  handleClickOrdering,
  handleClickSms,
  handleSignOut,
  filter,
  activeSelect,
  cardsIsFetching
}) {
  const { t } = useTranslation();
  if (cardsIsLoading || cardsIsFetching) return <ClipLoader size={30} speedMultiplier={0.6} color='#4f9d3a' />;
  return (
    <>
      <AnimatePresence>
        <motion.div initial={{ height: 0 }} animate={cards ? { height: 'auto' } : { height: '0' }} tw='overflow-hidden'>
          {cards?.card_counts > 0 ? (
            <AnimatedContainer
              cards={cards?.cards}
              handleClickOrdering={handleClickOrdering}
              handleClickSms={handleClickSms}
              activeSelect={activeSelect}
              handleSignOut={handleSignOut}
            />
          ) : (
            <>
              <BodyText
                text={activeSelect === 'not_active' ? t('my_cards.no_not_active_card') : t('my_cards.no_active_card')}
                variant={'bold'}
              />
              {activeSelect !== 'not_active' && (
                <BuyCardContainer handleSignOut={handleSignOut} twStyle={tw`max-w-[300px] mt-5`} />
              )}
            </>
          )}
        </motion.div>
      </AnimatePresence>
      {cards?.card_counts > 10 && <TablePagination dataCount={cards?.card_counts} filter={filter} />}
    </>
  );
}

const AnimatedContainer = ({ cards, handleClickOrdering, handleClickSms, activeSelect, handleSignOut }) => {
  return (
    <AnimatePresence>
      <motion.div tw='grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-5'>
        {cards?.map((item, i) => (
          <>
            <PrepaidCardContainer
              card={item}
              key={item?.uuid}
              handleClickOrdering={handleClickOrdering}
              handleSendSms={handleClickSms}
              idx={item?.uuid}
            />

            {i + 1 === cards.length && activeSelect !== 'not_active' && (
              <BuyCardContainer handleSignOut={handleSignOut} more />
            )}
          </>
        ))}
      </motion.div>
    </AnimatePresence>
  );
};
export default PrepaidCardslist;

const BuyCardContainer = ({ handleSignOut, more = false, twStyle }) => {
  const { t } = useTranslation();
  return (
    <button
      tw='w-full h-full flex flex-col justify-center items-center gap-5 max-h-[427px] min-h-[150px]'
      css={[twStyle]}
      className='dashed-container'
      onClick={handleSignOut}
    >
      <div tw='w-[30px] h-[30px] border-2 border-[#2A8640] rounded-full text-[#2A8640] flex items-center justify-center text-[25px]'>
        <span>+</span>
      </div>
      <SubBody text={t('my_cards.new')} twStyle={tw`text-[#2A8640]`} />
    </button>
  );
};
