import tw from 'twin.macro';

import { NormalTitle } from '@/components';

export default function SectionBlock({ title, children }) {
  return (
    <div className={'sm:max-w-app px-[12px] sm:px-5  mx-auto my-[100px]'}>
      <NormalTitle text={title} twStyle={tw`text-[22px] md:text-[40px] mb-10`} />
      {children}
    </div>
  );
}
