import { createSelector, createSlice } from '@reduxjs/toolkit';
import { path } from 'ramda';
import { PURGE } from 'redux-persist';

import { prepaidCardApi } from '@/app/api/prepaid';

// import { iinApplicationApi } from '@/app/api/iin-application';

const initialState = {
  mobile_phone: null,
  cardType: null,
  application_uuid: null,
  step: null
};

const baseSelector = state => state.app;

export const getSelectedCountry = createSelector([baseSelector], state => state.selectedCountry);
export const getApplyData = createSelector([baseSelector], state => ({
  country: state.selectedCountry,
  mobile_phone: state.mobile_phone,
  iin: state.iin
}));
export const getMobilePhone = createSelector([baseSelector], state => state.mobile_phone);
export const getIsSignDucumentsSuccess = createSelector([baseSelector], state => state.signDucumentsSuccess);
export const getCardType = createSelector([baseSelector], state => state.cardType);
export const getApplicationUuid = createSelector([baseSelector], state => state.application_uuid);
export const getStep = createSelector([baseSelector], state => state.step);
const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setApplyData: (state, { payload }) => {
      state.mobile_phone = payload.mobile_phone;
    },
    setApplicationId: (state, { payload }) => {
      state.application_uuid = payload;
    },
    setMobileNumber: (state, action) => {
      state.mobile_phone = action.payload;
    },
    setCardType: (state, { payload }) => {
      state.cardType = payload.replaceAll(' ', '');
    },
    setNewStep: (state, { payload }) => {
      state.step = payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(PURGE, () => initialState)
      .addMatcher(prepaidCardApi.endpoints.prepaidGetStatus.matchFulfilled, (state, action) => {
        const uuid = path(['payload', 'data', 'uuid'], action);
        state.application_uuid = uuid;
      });
  }
});

export const { setApplyData, setMobileNumber, setCardType, setApplicationId, setNewStep } = appSlice.actions;

export default appSlice.reducer;
