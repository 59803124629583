import { compose, filter, find, map, prop } from 'ramda';

export const getCountries = prop('data');

export const getAvailableCountries = compose(map(prop('code')), filter(prop('is_available')), getCountries);

export const transformCountries = compose(
  map(({ name, code }) => ({ value: code, label: name })),
  getCountries
);

export const getCodeSelectedCountry = (country, state) =>
  compose(
    prop('code'),
    find(({ code }) => code === country),
    getCountries
  )(state);

export function validateIIN(iin) {
  let new_iin = iin?.replace(/[^+\d]/g, '') || null,
    res = true,
    month = '',
    day = '',
    gender = '',
    sum = 0,
    j = 1;

  if (new_iin?.length == 12) {
    month = new_iin.substr(2, 2);
    day = new_iin.substr(4, 2);
    gender = new_iin.substr(6, 1);

    if (gender < 0 || gender > 9) {
      res = false;
    }

    if (month < 1 || month > 12) {
      res = false;
    }

    if (month == 4 || month == 6 || month == 9 || month == 11) {
      if (day < 1 || day > 30) {
        res = false;
      }
    } else if (month == 2) {
      if (day < 1 || day > 29) {
        res = false;
      }
    } else {
      if (day < 1 || day > 31) {
        res = false;
      }
    }

    for (let i = 0; i < new_iin.length - 1; i++) {
      sum += new_iin[i] * j;
      j += 1;
    }

    if (sum % 11 == 10) {
      sum = 0;
      j = 3;
      for (let i = 0; i < new_iin.length - 1; i++) {
        sum += new_iin[i] * j;
        j += 1;
        if (j == 12) {
          j = 1;
        }
      }
    }

    if (sum % 11 != new_iin[11]) {
      res = false;
    }
  }

  return res;
}

export const dataToCities = data => {
  let array = [];

  if (data?.length) {
    data?.map(i => array.push({ value: i.uuid, label: i.name }));
  }
  return array.sort((a, b) => a?.label.localeCompare(b?.label, 'ru'));
};

export const dataToCountries = data => {
  let array = [];
  if (data?.length) {
    data.map(i =>
      array.push({
        value: i.code,
        label: i.name
      })
    );
  }
  return array;
};

export const options = {
  left: 'Доставка',
  right: 'Самовывоз'
};

export const objectToString = data => {
  let pickUpAddress = '';
  if (data) {
    pickUpAddress = 'г.' + data.city + ', ул. ' + data.street + ', ';
    if (data.corpus) {
      pickUpAddress += `${data.corpus}-й корпус`;
    }
    if (data.entrance) {
      pickUpAddress += `${data.entrance}-й подъезд,`;
    }
    if (data.flat) {
      pickUpAddress += `кв ${data.flat}, `;
    }
    if (data.building) {
      pickUpAddress += `д. ${data.building}`;
    }
  }
  return pickUpAddress;
};

export const convertPan = pan => {
  let newString = '';
  pan?.replaceAll('.', '•');
  newString += pan?.slice(0, 4) + ' ••••' + ' •••• ' + pan?.slice(12, pan.length);
  return newString;
};

export const filterPrepaidCountries = countries => {
  const newArray = countries?.map(item => item?.code2.toLowerCase());
  console.log('new array', newArray);
  return newArray;
};
