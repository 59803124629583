import * as React from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { ThemeContext } from '@/contexts/theme-context';

function GooglePayIcon(props) {
  const location = useLocation().pathname;
  const { theme } = useContext(ThemeContext);
  const isDark = theme === 'dark' && location.includes('success');
  return (
    <svg width={52} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prefix__clip0_30_5926)'>
        <path
          d='M24.324 9.77v5.863h-1.9V1.155h5.038a4.64 4.64 0 011.759.308c.56.215 1.07.536 1.503.946.436.384.784.856 1.019 1.383.235.527.352 1.097.343 1.672a3.94 3.94 0 01-.338 1.683 4.015 4.015 0 01-1.024 1.39c-.881.823-1.968 1.233-3.262 1.233h-3.138zm0-6.832v5.054h3.185c.35.01.697-.051 1.02-.18.324-.13.616-.323.86-.569.24-.23.433-.505.564-.809a2.423 2.423 0 00-.565-2.73 2.48 2.48 0 00-.855-.581 2.523 2.523 0 00-1.024-.186h-3.185zM36.462 5.404c1.404 0 2.512.367 3.324 1.102.813.735 1.219 1.742 1.218 3.022v6.105h-1.817v-1.375h-.082c-.787 1.133-1.833 1.699-3.138 1.699-1.115 0-2.047-.324-2.797-.97a3.107 3.107 0 01-.843-1.09 3.045 3.045 0 01-.282-1.337c0-1.025.395-1.84 1.186-2.445.791-.606 1.847-.909 3.169-.91 1.127 0 2.056.202 2.786.606v-.425a2.081 2.081 0 00-.203-.91 2.124 2.124 0 00-.581-.736 2.722 2.722 0 00-1.834-.678c-1.06 0-1.9.438-2.518 1.314l-1.673-1.031c.92-1.294 2.282-1.941 4.085-1.941zM34.005 12.6c-.001.236.055.47.165.681.11.211.269.393.465.532.42.323.941.495 1.476.485a3.061 3.061 0 002.136-.869c.63-.58.944-1.26.944-2.041-.593-.462-1.418-.693-2.477-.693-.772 0-1.415.182-1.93.546-.52.37-.779.82-.779 1.359zM51.432 5.728L45.091 20h-1.96l2.352-4.994-4.17-9.278h2.065l3.014 7.116h.041l2.931-7.116h2.068z'
          fill={isDark ? '#fff' : '#000'}
        />
        <path
          d='M16.65 8.507c0-.567-.05-1.132-.147-1.691h-8.01v3.203h4.588c-.094.512-.293 1-.584 1.434-.292.434-.67.806-1.113 1.094v2.08h2.738c1.604-1.448 2.527-3.589 2.527-6.12z'
          fill='#4285F4'
        />
        <path
          d='M8.493 16.635c2.292 0 4.222-.737 5.63-2.008l-2.739-2.08c-.762.507-1.743.795-2.891.795-2.215 0-4.096-1.462-4.768-3.433H.905v2.143a8.385 8.385 0 003.13 3.345 8.628 8.628 0 004.458 1.238z'
          fill='#34A853'
        />
        <path
          d='M3.725 9.91a4.886 4.886 0 010-3.185V4.583H.905A8.177 8.177 0 000 8.317c0 1.297.31 2.576.904 3.735l2.82-2.143z'
          fill='#FBBC04'
        />
        <path
          d='M8.493 3.292A4.665 4.665 0 0111.75 4.54l2.424-2.374A8.257 8.257 0 008.493 0a8.628 8.628 0 00-4.458 1.238 8.385 8.385 0 00-3.13 3.345l2.82 2.143c.672-1.971 2.553-3.434 4.768-3.434z'
          fill='#EA4335'
        />
      </g>
      <defs>
        <clipPath id='prefix__clip0_30_5926'>
          <path fill='#fff' d='M0 0h51.429v20H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GooglePayIcon;
