import * as React from 'react';

function DownloadIcon(props) {
  return (
    <svg width='11' height='11' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7.9922 0.666016C7.5322 0.666016 7.15886 1.0391 7.15886 1.49935V10.301L4.65886 7.82769L3.48719 8.99936L7.39303 12.9319C7.71887 13.2569 8.26552 13.2569 8.59136 12.9319L12.4972 8.99936L11.3255 7.82769L8.82553 10.301V1.49935C8.82553 1.0391 8.4522 0.665932 7.9922 0.666016ZM1.32552 10.666C0.865521 10.666 0.492188 11.0394 0.492188 11.4994V13.166C0.492188 15.0069 1.98469 16.4994 3.82552 16.4994H12.1589C13.9997 16.4994 15.4922 15.0069 15.4922 13.166V11.4994C15.4922 11.0394 15.1189 10.666 14.6589 10.666C14.1989 10.666 13.8255 11.0394 13.8255 11.4994V13.166C13.8255 14.0869 13.0797 14.8327 12.1589 14.8327H3.82552C2.90469 14.8327 2.15886 14.0869 2.15886 13.166V11.4994C2.15886 11.0394 1.78552 10.666 1.32552 10.666Z'
        fill='#72BF44'
      />
    </svg>
  );
}

export default DownloadIcon;
