/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import 'twin.macro';

import React, { useContext, useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import tw from 'twin.macro';

import {
  useGetPrepaidCardsQuery,
  usePrepaidCardTransactionsQuery,
  usePrepaidSendSmsMutation
} from '@/app/api/my.documents-prepaid';
import { setApplyData } from '@/app/store/slices/app';
import { resetAuth } from '@/app/store/slices/auth';
import { setCardState } from '@/app/store/slices/cards';
import BigExclamationIcon from '@/assets/images/icons/BigExclamationIcon';
import PdfIcon from '@/assets/images/icons/PdfIcon';
import { BodyText, Button, Modal } from '@/components';
import ContainerSkeleton from '@/components/ui/container-skeleton/container-skeleton';
import ModalPortal from '@/components/ui/modal/portal';
import { Selector2 } from '@/components/ui/selector2/selector2';
import { ThemeContext } from '@/contexts/theme-context';
import AccountStatementModal from '@/views/account-statement-modal';
import PrepaidCardslist from '@/views/prepaid-cards-list';

function PrepaidCardDocuments({ mobile_phone }) {
  const [smsOpen, setSmsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [activeSelect, setActiveSelect] = useState('active');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filter = useSelector(state => state.cards);
  const { t, i18n } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const location = useLocation().pathname;
  const lang = i18n.language.split('-')[0];
  const {
    data: userCards,
    isLoading: cardsIsLoading,
    isFetching: cardsIsFetching,
    refetch: prepaidsRefetch
  } = useGetPrepaidCardsQuery(filter);

  const {
    data: cardTransactions,
    isLoading: transactionsLoading,
    isFetching: transactionsFetching
  } = usePrepaidCardTransactionsQuery(activeCard?.uuid, {
    skip: !activeCard || smsOpen
  });
  const [sendSms, { isLoading, isSuccess, error }] = usePrepaidSendSmsMutation();

  const options = [
    { title: t('my_cards.selectors.active'), value: 'active' },
    { title: t('my_cards.selectors.not_active'), value: 'not_active' }
  ];

  const options2 = useMemo(
    () => [
      { title: t('my_cards.statement.selectors.last'), value: true },
      { title: t('my_cards.statement.selectors.all'), value: false }
    ],
    [t]
  );

  const handleClickSms = item => {
    setActiveCard(item);
    setSmsOpen(true);
    sendSms(item?.uuid);
  };

  const handleClickOrdering = item => {
    setActiveCard(item);
    setOpen(true);
  };

  const handleSignOut = () => {
    dispatch(resetAuth());
    navigate('/apply');
  };

  useEffect(() => {
    prepaidsRefetch();
  }, []);

  useEffect(() => {
    dispatch(setCardState({ cardState: activeSelect }));
  }, [activeSelect]);
  useEffect(() => {
    dispatch(setApplyData({ mobile_phone }));
  }, [mobile_phone]);

  return (
    <div tw='flex flex-col gap-10 min-w-[100%]' css={[userCards && tw`h-full`]}>
      <div tw='sm:max-w-[30%]'>
        <Selector2 items={options} defaultActiveItem={'active'} getActiveItem={setActiveSelect} />
      </div>
      {userCards ? (
        <PrepaidCardslist
          activeSelect={activeSelect}
          handleSignOut={handleSignOut}
          cards={userCards?.data}
          filter={filter}
          cardsIsLoading={cardsIsLoading}
          cardsIsFetching={cardsIsFetching}
          handleClickOrdering={handleClickOrdering}
          handleClickSms={handleClickSms}
        />
      ) : (
        <ContainerSkeleton twStyle={tw`min-h-[324px] max-h-[427px] w-full h-full`} />
      )}
      <a
        href={'https://bankffin.kz/storage/docs/NWW1FjNyViuLzFlJjAPQcLrwpfu4NvzY9g7d2pD4.pdf'}
        target='_blank'
        rel='noreferrer'
      >
        <div tw='flex items-center gap-2 border border-green-alpha max-w-max p-3 rounded-xl'>
          <PdfIcon color={theme === 'dark' ? 'rgba(79, 184, 78)' : '#2A8640'} />
          <BodyText text={t('my_cards.tariffs')} twStyle={tw`text-green`} />
        </div>
      </a>

      <AccountStatementModal
        activeCard={activeCard}
        open={open}
        setOpen={setOpen}
        transactions={cardTransactions}
        options2={options2}
        transactionsLoading={transactionsLoading}
        transactionsFetching={transactionsFetching}
      />
      <ModalPortal>
        <Modal open={smsOpen} setOpen={setSmsOpen} twStyle={tw`min-w-[200px] max-w-[400px] rounded-2xl text-center`}>
          <div tw='p-5 flex flex-col gap-5 items-center '>
            {isLoading && <ClipLoader size={30} speedMultiplier={0.6} color='#4f9d3a' />}
            {isSuccess && (
              <>
                <BodyText twStyle={tw`text-center`} text={t('my_cards.sms_text', { mobile_phone: mobile_phone })} />
                <Button variant={'secondary'} onClick={() => setSmsOpen(false)}>
                  Ок
                </Button>
              </>
            )}
            {error && (
              <>
                <div tw='w-[104px] h-[104px] flex items-center justify-center rounded-full mx-auto bg-yellow-alpha20'>
                  <BigExclamationIcon />
                </div>
                <BodyText text={error?.data?.error?.description?.mobile_phone || error?.data?.error?.description} />
              </>
            )}
          </div>
        </Modal>
      </ModalPortal>
    </div>
  );
}

export default PrepaidCardDocuments;
