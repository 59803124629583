import 'twin.macro';

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PURGE } from 'redux-persist';

import LandingPrepaidBanner from '@/views/landing-prepaid/landing-prepaid-banner';
import LandingPrepaidConditions from '@/views/landing-prepaid/landing-prepaid-conditions';
import LandingPrepaidHowWorks from '@/views/landing-prepaid/landing-prepaid-how-works';
import LandingPrepaidQA from '@/views/landing-prepaid/landing-prepaid-qa';

function LandingPrepaid() {
  const location = useLocation().pathname;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: PURGE, result: () => null });
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div tw='bg-primary'>
      <LandingPrepaidBanner />
      <LandingPrepaidHowWorks />
      <LandingPrepaidConditions />
      <LandingPrepaidQA />
    </div>
  );
}

export default LandingPrepaid;
