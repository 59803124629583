import 'react-datepicker/dist/react-datepicker.css';
import 'twin.macro';

import { AnimatePresence, motion } from 'framer-motion';
import jwt_decode from 'jwt-decode';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import { useGetUserInfoQuery } from '@/app/api/my-documents';
import { getAccessToken } from '@/app/store/slices/auth';
import { DeviceInfoContext } from '@/contexts/device-info-context';
import { ThemeContext } from '@/contexts/theme-context';
import MyDocumentsHeader from '@/views/headers/my-documents-header';

import PrepaidCardDocuments from './prepaid-card/prepaid-card-documents';

function MyDocumentsMainProfile() {
  const { theme, setTheme } = useContext(ThemeContext);
  const { isMobile } = useContext(DeviceInfoContext);
  const token = useSelector(getAccessToken);
  const { data: userInfo, isLoading: userInfoIsLoading, isFetching: userInfoRefetching } = useGetUserInfoQuery();

  const location = useLocation();

  const userLastName = userInfo?.data?.full_name?.split(' ')[0];
  const userName = userInfo?.data?.full_name?.split(' ')[1].split('')[0] + '.';

  const mobile_phone = jwt_decode(token).mobile_phone;

  // useEffect(() => {
  //   setTheme('dark');
  // }, [setTheme]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    document.getElementsByTagName('body');
  }, []);
  if (userInfoIsLoading || userInfoRefetching) {
    return (
      <div tw='flex justify-center'>
        <ClipLoader color='green' />
      </div>
    );
  }

  return (
    <AnimatePresence>
      <motion.div
        tw='flex flex-col gap-10 relative overflow-hidden'
        initial={{ opacity: 0 }}
        animate={userInfoRefetching || userInfoIsLoading ? { opacity: 0 } : { opacity: 1 }}
      >
        <div tw='sm:max-w-app px-[12px] sm:px-5  mx-auto my-[100px] flex flex-col gap-10 w-full h-full'>
          <MyDocumentsHeader
            isMobile={isMobile}
            mobile_phone={mobile_phone}
            userLastName={userLastName}
            userName={userName}
          />
          <div tw='flex w-full'>
            <PrepaidCardDocuments mobile_phone={mobile_phone} />
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

export default MyDocumentsMainProfile;
