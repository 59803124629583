// requestTimestampSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lastRequestTime: new Date()
};

const requestTimeSlice = createSlice({
  name: 'requestTimeSlice',
  initialState: initialState, // Initial state should be null or a default timestamp
  reducers: {
    setRequestTimestamp: (state, { payload }) => {
      state.lastRequestTime = payload;
    }
  }
});

export const { setRequestTimestamp } = requestTimeSlice.actions;
export default requestTimeSlice.reducer;
