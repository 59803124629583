import * as React from 'react';

function SmsIcon(props) {
  return (
    <svg width={72} height={72} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect x={16} y={11} width={29} height={49} rx={3} fill='url(#prefix__paint0_linear_1179_22543)' />
      <rect x={24.7} y={14} width={11.6} height={2} rx={1} fill='#fff' fillOpacity={0.9} />
      <g filter='url(#prefix__filter0_bi_1179_22543)'>
        <rect x={25} y={29} width={32} height={24} rx={4} fill='#72BF44' fillOpacity={0.5} />
      </g>
      <rect x={30} y={35} width={20} height={2} rx={1} fill='#fff' fillOpacity={0.9} />
      <rect x={30} y={40} width={20} height={2} rx={1} fill='#fff' fillOpacity={0.9} />
      <rect x={30} y={45} width={12} height={2} rx={1} fill='#fff' fillOpacity={0.9} />
      <defs>
        <linearGradient
          id='prefix__paint0_linear_1179_22543'
          x1={16}
          y1={11}
          x2={41.508}
          y2={9.245}
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#4FB84E' />
          <stop offset={1} stopColor='#2A8640' />
        </linearGradient>
        <filter
          id='prefix__filter0_bi_1179_22543'
          x={21}
          y={25}
          width={40}
          height={32}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImageFix' stdDeviation={2} />
          <feComposite in2='SourceAlpha' operator='in' result='effect1_backgroundBlur_1179_22543' />
          <feBlend in='SourceGraphic' in2='effect1_backgroundBlur_1179_22543' result='shape' />
          <feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={0.2} dy={0.1} />
          <feGaussianBlur stdDeviation={0.5} />
          <feComposite in2='hardAlpha' operator='arithmetic' k2={-1} k3={1} />
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0' />
          <feBlend in2='shape' result='effect2_innerShadow_1179_22543' />
        </filter>
      </defs>
    </svg>
  );
}

export default SmsIcon;
