import { api } from '.';
import { APIUrl } from './API-Url';

const prepaidGeneralInfoApi = api.injectEndpoints({
  endpoints: build => ({
    getPrepaidGeneralInfo: build.query({
      query: () => ({
        url: `${APIUrl}/prepaid/general-info/`,
        method: 'GET'
      })
    })
  })
});
export const { useGetPrepaidGeneralInfoQuery } = prepaidGeneralInfoApi;
