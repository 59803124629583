import 'twin.macro';

import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { getApplicationUuid } from '@/app/store/slices/app';
import { getAccessToken } from '@/app/store/slices/auth';

import WebSocketComponent from '../websocket-component';

const StepLayout = () => {
  const application_uuid = useSelector(getApplicationUuid);
  const token = useSelector(getAccessToken);

  return (
    <>
      <WebSocketComponent application_uuid={application_uuid} access_token={token} />
      <div className={''}>
        <div tw='pt-[3px] max-w-content mx-auto pb-[48px]'>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default StepLayout;
