import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
  limit: 10,
  offset: 0,
  cardState: 'active'
};

const cardsSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    setPage(state, action) {
      state.offset = action.payload.offset;
    },
    setCardState(state, action) {
      state.cardState = action.payload.cardState;
    }
  },
  extraReducers: builder => {
    builder.addCase(PURGE, () => initialState);
  }
});

export const { setPage, setCardState } = cardsSlice.actions;
export default cardsSlice.reducer;
