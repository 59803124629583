import * as React from 'react';

function ProfileIcon(props) {
  return (
    <svg width={16} height={16} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M8 8a3.333 3.333 0 100-6.667A3.333 3.333 0 008 8zM8 9.666c-3.34 0-6.06 2.24-6.06 5a.33.33 0 00.333.334h11.454a.33.33 0 00.333-.334c0-2.76-2.72-5-6.06-5z'
        fill='#B7BBBD'
      />
    </svg>
  );
}

export default ProfileIcon;
