import React from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { Caption } from '@/components';
import SectionBlock from '@/components/ui/section-block/section-block';

function LandingPrepaidConditions() {
  const { t, i18n } = useTranslation();
  return (
    <SectionBlock title={t('landing.conditions.title')}>
      <table tw='min-w-full divide-y divide-grey my-10'>
        <tbody tw='divide-y divide-grey '>
          <tr>
            <td tw='p-5 bg-secondary rounded-t-2xl align-top '>{t('landing.conditions.line1.column')}</td>
            <td tw='font-bold p-5'>
              {t('landing.conditions.line1.value')}

              <br />
              <Caption text={<span>{t('landing.conditions.line1.caption')}</span>} twStyle={tw`text-secondary`} />
            </td>
          </tr>
          <tr>
            <td tw='p-5 bg-secondary align-top'>{t('landing.conditions.line2.column')}</td>
            <td tw='font-bold p-5 flex sm:flex-row flex-col gap-5'>
              <div>
                {t('landing.conditions.line2.value.first.title')}
                <Caption
                  text={<span>{t('landing.conditions.line2.value.first.caption')}</span>}
                  twStyle={tw`text-secondary`}
                />
              </div>
              <div>
                {t('landing.conditions.line2.value.second.title')}
                <Caption
                  text={<span>{t('landing.conditions.line2.value.second.caption')}</span>}
                  twStyle={tw`text-secondary`}
                />
              </div>
              <div>
                {t('landing.conditions.line2.value.third.title')}
                <Caption
                  text={<span>{t('landing.conditions.line2.value.third.caption')}</span>}
                  twStyle={tw`text-secondary`}
                />
              </div>
              <div>
                {t('landing.conditions.line2.value.fourth.title')}
                <Caption
                  text={<span>{t('landing.conditions.line2.value.fourth.caption')}</span>}
                  twStyle={tw`text-secondary`}
                />
              </div>
              <div>
                {t('landing.conditions.line2.value.fifth.title')}
                <Caption
                  text={<span>{t('landing.conditions.line2.value.fifth.caption')}</span>}
                  twStyle={tw`text-secondary`}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td tw='p-5 bg-secondary align-top '>{t('landing.conditions.line3.column')} </td>
            <td tw='font-bold p-5'>{t('landing.conditions.line3.value')}</td>
          </tr>

          <tr>
            <td tw='p-5 bg-secondary align-top'>{t('landing.conditions.line4.column')}</td>
            <td tw='font-bold p-5'>
              {t('landing.conditions.line4.value')}
              <Caption text={t('landing.conditions.line4.caption')} twStyle={tw`text-secondary`} />
            </td>
          </tr>
          <tr>
            <td tw='p-5 bg-secondary align-top'>{t('landing.conditions.line5.column')}</td>
            <td tw='font-bold p-5'>{t('landing.conditions.line5.value')}</td>
          </tr>
          <tr tw=''>
            <td tw='p-5 bg-secondary align-top'>{t('landing.conditions.line6.column')}</td>
            <td tw='font-bold p-5 '>
              {/* {t('landing.conditions.line6.value')}
              <Caption text={<span>{t('landing.conditions.line6.caption')}</span>} twStyle={tw`text-secondary`} />
              <Caption text={<span>{t('landing.conditions.line6.caption')}</span>} twStyle={tw`text-secondary`} /> */}
              {i18n.language === 'ru' ? 'нет' : 'жоқ'}
            </td>
          </tr>
          <tr>
            <td tw='p-5 bg-secondary align-top'>{t('landing.conditions.line7.column')}</td>
            <td tw='font-bold p-5'>{t('landing.conditions.line7.value')}</td>
          </tr>
          <tr>
            <td tw='p-5 bg-secondary  rounded-b-2xl align-top'>{t('landing.conditions.line8.column')}</td>
            <td tw='font-bold p-5'>{t('landing.conditions.line8.value')}</td>
          </tr>
        </tbody>
      </table>
    </SectionBlock>
  );
}

export default LandingPrepaidConditions;
