import 'twin.macro';

import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
// import ReCAPTCHA from 'react-google-recaptcha';
import { useTimer } from 'react-timer-hook';

import { Button, Input2, OtpInput, SubBody } from '@/components';

const LoginContent = ({
  control,
  mobilePhone,
  verifySubmit,
  authSuccess,
  handleCheckRobot,
  recaptchaValue,
  loading,
  reset,
  authReset,
  sendOtpError,
  getValues,
  setValue,
  authOpen,
  errors,
  mobilePhoneValid,
  twStyle,
  setCountryFormatLength,
  isExeceptionCountry,
  setIsExeceptionCountry
}) => {
  const [applyAuth, setApplyAuth] = useState(false);
  const [timerEnd, setTimerEnd] = useState(false);
  const [invalidOTP, setInvalidOTP] = useState(false);
  const phone = mobilePhone?.replaceAll(' ', '');
  const { t, i18n } = useTranslation();
  const lang = i18n.language.split('-')[0];
  useEffect(() => {
    if (sendOtpError?.data?.error?.code?.toLowerCase() === 'invalid_otp') {
      setApplyAuth(true);
      setInvalidOTP(true);
    }
  }, [sendOtpError]);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 120);

  const { seconds, minutes, start, restart } = useTimer({ expiryTimestamp: time, onExpire: () => setTimerEnd(true) });

  useEffect(() => {
    setApplyAuth(authSuccess);
    start();
  }, [authSuccess]);

  const resetHandler = () => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + 120);
    setTimerEnd(false);
    reset('code');
    authReset();
    restart(time);
  };

  useEffect(() => {
    phone && setValue('mobile_phone', phone);
  }, [phone]);

  useEffect(() => {
    if (mobilePhone === null) {
      setApplyAuth(false);
    }
  }, [mobilePhone]);

  const handleKeyPress = e => {
    e.charCode === 13 && mobilePhoneValid && verifySubmit();
  };
  console.log('mobile_phone', mobilePhone);

  return (
    <div tw='flex flex-col gap-2'>
      <div tw='bg-secondary p-5 space-y-4 px-12' css={[twStyle]}>
        {applyAuth ? (
          <>
            <CurrencyFormat value={phone} displayType={'text'} format='+##################' tw='font-bold text-s18' />
            <OtpInput
              control={control}
              name='code'
              label={''}
              withPhone={true}
              shouldAutoFocus={applyAuth}
              rules={{ required: { value: true, message: 'Это поле не может быть пустым' } }}
            />
            <div tw='mt-3 text-center'>
              {timerEnd ? (
                <Button variant='link' onClick={resetHandler}>
                  {t('auth.resend')}
                </Button>
              ) : lang === 'ru' ? (
                <SubBody
                  text={`${t('auth.resend_after')} ${minutes < 10 ? '0' + minutes : minutes}:${
                    seconds < 10 ? '0' + seconds : seconds
                  } сек`}
                />
              ) : (
                <SubBody
                  text={` ${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds} ${t(
                    'auth.resend_after'
                  )}`}
                />
              )}
            </div>
          </>
        ) : (
          <Input2
            // onKeyPress={handleKeyPress}
            label={t('steps.apply.mobile_phone')}
            name='mobile_phone'
            placeholder='+7'
            control={control}
            mask='+7 999 999 99 99'
            rules={{ required: { value: true, message: 'Это поле не может быть пустым' } }}
            isRequired={true}
          />
        )}
      </div>

      <div tw='bg-secondary p-5 sm:rounded-b-2xl mt-3' css={[twStyle]}>
        {!authSuccess && (
          <Button variant='secondary' onClick={verifySubmit} disabled={recaptchaValue} loading={loading}>
            {t('auth.get_sms')}
          </Button>
        )}
      </div>
    </div>
  );
};
export default LoginContent;
