import 'twin.macro';

import React from 'react';

import FacebookIcon from '@/assets/images/social/FacebookIcon';
import InstagramIcon from '@/assets/images/social/InstagramIcon';
import TiktocIcon from '@/assets/images/social/TiktocIcon';
const apps = [
  {
    icon: <InstagramIcon />,
    link: 'https://www.instagram.com/bankffin.kz/'
  },
  {
    icon: <FacebookIcon />,
    link: 'https://www.facebook.com/bankffin.kz'
  },
  {
    icon: <TiktocIcon />,
    link: 'https://www.tiktok.com/@bankffin.kz?_d=secCgYIASAHKAESPgo8yEEHeluxBl8xQIn2jkt73RwH4aEmS1sBylYYKHHXdO%2FpJD5Unk1Y6ySplmX8fgIau3EbRWKAvSuk4Jy7GgA%3D&_r=1&language=ru&sec_uid=MS4wLjABAAAAqiW2M57HqTThoYDf_ElCCQE2ec1xnISFiwgt30HlHyLKFzMyC8ZjjWxrP8kGzmEa&sec_user_id=MS4wLjABAAAAqiW2M57HqTThoYDf_ElCCQE2ec1xnISFiwgt30HlHyLKFzMyC8ZjjWxrP8kGzmEa&share_app_id=1233&share_author_id=7008486303806505985&share_link_id=32981fb4-55b4-4ba3-ae85-a1e8d14081da&source=h5_m&timestamp=1654773109&u_code=dkidhhb95f1c26&ugbiz_name=Account&user_id=7008486303806505985&utm_campaign=client_share&utm_medium=android&utm_source=copy'
  }
];
function SocialMedias() {
  return (
    <div tw='flex gap-5'>
      {apps.map((item, idx) => (
        <a href={item.link} key={idx}>
          <div tw='w-[40px] h-[40px] flex items-center justify-center bg-primary rounded-full'>{item.icon}</div>
        </a>
      ))}
    </div>
  );
}

export default SocialMedias;
