import { css, Global } from '@emotion/react';
import React from 'react';
import tw, { GlobalStyles as BaseStyles } from 'twin.macro';

const customStyles = css`
  :root {
    --color-white: #ffffff;
    --color-white-alpha87: rgba(255, 255, 255, 0.87);
    --color-white-alpha06: rgba(255, 255, 255, 0.06);
    --color-white-alpha25: rgba(255, 255, 255, 0.25);
    --color-black: #17191a;
    --color-black-alpha20a: rgba(0, 0, 0, 0.2);
    --color-biometry: #202020;
    --color-overlay: rgba(0, 0, 0, 0.8);
    --color-dark-grey: #1a1a1a;
    --color-progress-50: #e7f0e1;
    --color-progress-150: #eafae6;
    --dark-grey: #8e8e93;
    --lighter-grey: #e8eaeb;
    --light-grey: #f5f6f7;
    --green-primary: #2a8640;
    --green-primary-alpha: rgba(114, 191, 68, 0.2);
    --yellow-alpha20: rgba(238, 187, 8, 0.2);
    --red-alpha20: rgba(251, 85, 85, 0.1);
    --green-secondary: rgb(79, 184, 78);
    --green-secondary-alpha60: rgba(79, 184, 78, 0.6);
    --green-dark: #2d7d2f;
    --light-red: #fb5555;
    --dark-red: #c24141;
    --modal: #0000009c;
    --middle-grey: #8e8e93;
    --color-grey-50: #ddf6ce;
    --color-grey-150: #454545;
    --color-alpha-orange: rgba(255, 164, 29, 0.1);
    --color-alpha-red: rgba(251, 85, 85, 0.1);
    --color-green-gradient: linear-gradient(83.37deg, #4fb84e 10.41%, #2a8640 89.59%);
  }
  .light {
    --accent: #2a8640;
    --bg-primary: var(--light-grey);
    --bg-secondary: var(--color-white);
    --bg-secondary-inverted: #282828;
    --bg-progress: var(--color-progress-50);
    --bg-wrapper: var(--color-white);
    --bg-cardcontainer: rgba(191, 191, 191, 0.2);
    --bg-extra: var(--color-white);
    --bg-input: var(--light-grey);
    --bg-green: var(--green-primary);
    --bg-green-secondary: var(--green-secondary);
    --bg-additional: rgba(238, 238, 238, 0.94);
    --bg-toolbar: rgba(238, 238, 238, 0.94);
    --bg-selector: rgba(79, 157, 58, 0.1);
    --text-primary: var(--color-black);
    --text-primary-inverted: var(--color-white-alpha87);
    --text-secondary: #5c5c5c;
    --text-tertiary: var(--dark-grey);
    --text-green: var(--green-primary);
    --text-green-secondary: var(--green-secondary);
    --text-green-hover: var(--green-dark);
    --text-error: var(--dark-red);
    --text-inactive: #939393;
    --border-button: var(--lighter-grey);
    --border-input-active: var(--green-secondary);
    --border-input-invalid: var(--dark-red);
    --border-grey: var(--lighter-grey);
    --grey-footer: var(--color-grey-50);
    --divide-grey: var(--lighter-grey);
    --bg-border: #e8eaeb;
    --landing-title: var(--color-dark);
    --landing-accent: var(--green-primary);
    --bg-accent-alpha: #d9dcf8;
  }
  .dark {
    --accent: #4fb84e;
    --bg-primary: var(--color-dark-grey);
    --bg-secondary: #282828;
    --bg-secondary-inverted: var(--color-white);
    --bg-progress: var(--color-progress-150);
    --bg-wrapper: var(--color-biometry);
    --bg-cardcontainer: rgba(255, 255, 255, 0.1);
    --bg-extra: var(--color-dark-grey);
    --bg-input: #00000033;
    --bg-green: var(--green-secondary);
    --bg-alpha-orange: var(--color-alpha-orange);
    --bg-alpha-red: var(--color-alpha-red);
    --bg-green-secondary: var(--green-secondary);
    --bg-additional: #454545;
    --bg-toolbar: rgba(58, 58, 58, 0.94);
    --bg-selector: rgba(114, 191, 68, 0.1);
    --text-primary: var(--color-white-alpha87);
    --text-primary-inverted: var(--color-black);
    --text-secondary: #b7bbbd;
    --text-inactive: #7a7a7a;
    --text-tertiary: var(--color-white-alpha87);
    --text-green: #4fb84e;
    --text-green-secondary: var(--green-primary);
    --text-green-hover: var(--green-secondary);
    --text-error: var(--light-red);
    --border-button: var(--middle-grey);
    --border-input-active: var(--green-primary);
    --border-input-invalid: var(--dark-red);
    --border-grey: var(--color-white-alpha06);
    --grey-footer: var(--color-grey-150);
    --divide-grey: var(--color-white-alpha25);
    --bg-border: #454545;
    --landing-title: #70ff6e;
    --landing-accent: #70ff6e;
    --bg-accent-alpha: #2b2d42;
  }
  body {
    font-family: 'Noto Sans', sans-serif;
    ${tw`bg-primary text-primary antialiased transition-all duration-200`}
  }

  body {
    overflow-x: hidden;
  }
  svg {
    fill: currentColor;
  }
  .swiper-button-disabled svg {
    cursor: not-allowed;
    fill: rgba(255, 255, 255, 0.06) !important;
  }

  .step-style + .LabelContainer-0-2-8 {
    font-family: 'Noto Sans', sans-serif;
  }

  .step-style + .LabelContainer-0-2-8 .Label-0-2-9 {
    ${tw`text-secondary`}
    margin-top: 0.4rem;
  }

  .step-style.active + .LabelContainer-0-2-8 .Label-0-2-9 {
    font-weight: 700;
    ${tw`text-primary`}
  }

  .swiper-button-disabled svg path {
    cursor: not-allowed;
    fill: rgba(255, 255, 255, 0.1) !important;
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .skeleton {
    background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
  }

  @keyframes shine {
    to {
      background-position: right -40px top 0;
    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    height: 0px;
  }

  ::-webkit-scrollbar-track {
    // background: var(--bg-primary);
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #4f9d3a;
    // border-radius: 16px;
    // border: 3px solid #4f9d3a;
  }

  -webkit-tap-highlight-color: transparent;

  :root {
    scrollbar-color: #4f9d3a;
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  .pagination {
    ${tw`flex items-center justify-center gap-3`}
  }
  .pagination .selected {
    ${tw`bg-green-secondary text-white w-[30px] h-[30px] flex items-center justify-center font-bold rounded-full duration-75`}
  }
`;
const GlobalStyles = () => (
  <>
    <BaseStyles />
    <Global styles={customStyles} />
  </>
);

export default GlobalStyles;
