import 'twin.macro';

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CardImage from '@/assets/images/banner.webp';

import CardBanner from '../card-banner';

function LandingPrepaidBanner() {
  const { t } = useTranslation();

  const benefits = useMemo(
    () => [
      t('landing.banner.subtitle.benefit1'),
      t('landing.banner.subtitle.benefit2'),
      t('landing.banner.subtitle.benefit3')
    ],
    [t]
  );
  return (
    <>
      <CardBanner
        title={t('landing.banner.title')}
        benefits={benefits}
        buttonText={t('landing.banner.button')}
        navigateUrl={'/apply'}
        cardImage={CardImage}
      />
    </>
  );
}

export default LandingPrepaidBanner;
