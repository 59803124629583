import * as React from 'react';

function ChevronDown(props) {
  return (
    <svg width={25} height={24} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M20.42 8.95l-6.52 6.52c-.77.77-2.03.77-2.8 0L4.58 8.95'
        stroke='var(--accent)'
        fill='none'
        strokeWidth={1.5}
        strokeMiterlimit={10}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export default ChevronDown;
