import { api } from '.';
import { APIUrl } from './API-Url';

const mdPrepaidApi = api.injectEndpoints({
  endpoints: build => ({
    getPrepaidCards: build.query({
      query: ({ offset, limit, cardState }) => ({
        url: `${APIUrl}/lk-user/prepaids/`,
        method: 'GET',
        params: { offset, limit, state: cardState }
      })
    }),
    prepaidSendSms: build.mutation({
      query: uuid => ({
        url: `${APIUrl}/lk-user/prepaids/${uuid}/send-sms/`,
        method: 'POST',
        body: { uuid }
      })
    }),
    prepaidCardTransactions: build.query({
      query: uuid => ({
        url: `${APIUrl}/lk-user/prepaids/${uuid}/transactions/`,
        method: 'GET'
      })
    }),
    prepaidCreateOrdering: build.mutation({
      query: uuid => ({
        url: `${APIUrl}/lk-user/prepaids/create-document/`,
        method: 'POST',
        body: { uuid },
        responseHandler: async response => {
          if (response?.status === 200) {
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            const filename = 'Выписка';
            // response.headers.get('Content-Disposition');
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${filename}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
        }
      })
    })
  })
});

export const {
  useGetPrepaidCardsQuery,
  usePrepaidSendSmsMutation,
  usePrepaidCardTransactionsQuery,
  usePrepaidCreateOrderingMutation
} = mdPrepaidApi;
