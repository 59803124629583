import React from 'react';

function TickIcon({size= 24, color = 'currentColor'}) {
  return (
    <div>
      <svg width={size} height={size} fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4 11.5L9.825 18 20 6'
          stroke={color}
          strokeWidth={1.5}
          strokeLinecap='round'
          fill='none'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
}

export default TickIcon;
