export const apply_kz = {
  title: 'Телефон нөмірін енгізіңіз',
  subtitle: 'оған карта бекітілетін болады',
  mobile_phone: 'Телефон',
  checkbox: {
    text: 'келісемін',
    link: 'алдын ала төленген карталарды шығару және қызмет көрсету шарттарымен'
  }
};

export const verify_kz = {
  title: 'Кодты енгізіңіз',
  subtitle: 'Код {{phone}} нөмірге жіберілді',
  change_number: 'Нөмірді өзгерту',
  send_after: 'Кодты {{seconds}} сек кейін қайта жіберу',
  send: 'Қайта жіберу'
};

export const choice_product_kz = {
  title: 'Картаның атаулы құнын таңдаңыз',
  usd: 'доллар',
  comission: 'Шығару комиссиясы — \n{{amount}} теңге',
  buy: 'Сатып алу {{amount}} $',
  exchange: 'Айырбас бағамы — бір доллар үшін {{amount}} теңге',
  modal: {
    choosed: 'Сіз {{amount}}$ картаны таңдадыңыз.',
    comission: 'Құны, комиссияны қоса алғанда {{amount}}$',
    confirm: 'Растаймын'
  }
};

export const success_kz = {
  title: 'Цифрлық алдын ала төленген карта № {{number}} \n сәтті шығарылды',
  subtitle: 'Бұл нөмірді сақтаңыз және картаның қалған деректері бар СМС күтіңіз ',
  button: '«Менің құжаттарым»  бөліміне өту',
  subtitle2: '(30 минут ішінде келеді).',
  subtitle3: 'Барлық карталарыңыз, балансыңыз және операциялар туралы көшірме ',
  warning: 'Карта телефон нөміріңізге бекітілген, сондықтан оны және карта деректерін құпия сақтаңыз',
  bunner: {
    title: 'Картаны электрондық әмиянға қосыңыз',
    subtitle: 'және сатып алуларды смартфонмен төлеңіз'
  }
};

export const waiting_kz = {
  create_card: 'Карта жасау үдерісі жүріп жатыр',
  no_more_5: 'Үдеріс 5 минуттан аспайды',
  payment: 'Төлемді өңдеу жүріп жатыр',
  wait: 'Күте тұрыңыз...'
};

export const error_descriptions_kz = {
  rejected: 'Төлеміңіз қабылданбады. Prepaid картаны рәсімдеу үшін қайта өтініш беріңіз',
  canceled: 'Өтінішіңіз жойылды. Prepaid картаны рәсімдеу үшін қайта өтініш беріңіз',
  technical: 'Техникалық себептерге байланысты қызмет уақытша қолжетімсіз',

  session: {
    title: 'Сессия уақыты аяқталды',
    subtitle: 'Prepaid картаны рәсімдеу үшін басынан бастаңыз',
    button: 'Басынан бастаңыз'
  }
};

export const buttons_kz = {
  card_page: 'Карта бетіне',
  continue: 'Әрі қарай',
  my_cards: '«Менің карталарым»  бөліміне өту',
  try: 'Қайталаңыз',
  main_page: 'Басты бетке оралу'
};
