import 'twin.macro';

import React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import tw from 'twin.macro';

import { ThemeContext } from '@/contexts/theme-context';
function LangToggle({ handleChangeLanguage }) {
  const { t, i18n } = useTranslation();
  const { theme } = useContext(ThemeContext);
  const lang = i18n.language.split('-')[0];

  console.log(lang);
  return (
    <button onClick={handleChangeLanguage}>
      <p
        tw='sm:w-[32px] sm:h-[32px] h-[28px] w-[28px] flex items-center justify-center bg-secondary rounded-full text-s12 text-secondary'
        css={[theme === 'light' && tw`bg-primary`]}
      >
        {lang === 'kk' ? 'Қаз' : 'Рус'}
      </p>
    </button>
  );
}

export default LangToggle;
